import React from 'react';
import Button from '@chaskiq/components/src/components/Button';
import CircularProgress from '@chaskiq/components/src/components/Progress';
import I18n from '../shared/FakeI18n';

import graphql from '@chaskiq/store/src/graphql/client';

import { CAMPAIGN_SUBSCRIPTION_TOGGLE } from '@chaskiq/store/src/graphql/queries';

interface IUnSubscribeDataType {
  state?: string;
  email?: string;
}

export default function UnSubscribe({ match }) {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<IUnSubscribeDataType>({});

  React.useEffect(() => {
    toggleSubscription();
  }, []);

  function reSubscribe() {
    toggleSubscription(true);
  }

  function subscribe() {
    toggleSubscription(false);
  }

  function toggleSubscription(op = false) {
    setLoading(true);
    graphql(
      CAMPAIGN_SUBSCRIPTION_TOGGLE,
      {
        encoded: match.params.subscriber,
        op: op,
      },
      {
        success: (data) => {
          setLoading(false);
          setData(data.campaignSubscriptionToggle);
        },
        error: () => {
          console.log('errorroor');
        },
      }
    );
  }

  return (
    <div className="max-w-7xl mx-auto py-12 sm:px-6 lg:px-8 bg-gray-100 h-screen">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="sm:flex sm:items-start sm:justify-between">
              {loading && <CircularProgress />}

              {!loading && (
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                    {I18n.t('campaign.unsubscribe.status', {
                      state: data.state,
                    })}
                  </h3>
                  <div className="mt-2 max-w-xl text-sm leading-5 text-gray-500 dark:text-gray-300">
                    {data.state === 'unsubscribed' && (
                      <p>
                        {I18n.t('campaign.unsubscribe.success', {
                          email: data.email,
                        })}
                        <Button
                          onClick={reSubscribe}
                          className="ml-4 my-3"
                          size="small"
                        >
                          {I18n.t('campaign.unsubscribe.resubscribe')}
                        </Button>
                      </p>
                    )}

                    {data.state === 'subscribed' && (
                      <p>
                        <Button
                          onClick={subscribe}
                          className="ml-4 my-3"
                          size="small"
                        >
                          {I18n.t('campaign.unsubscribe.cancel')}
                        </Button>
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
