import React, { useEffect, useState } from 'react';

import GraphComponent from '../GraphView';
import TableComponent from '../TableView';
import { BsGrid3X3 } from 'react-icons/bs';
import DateRangePicker from '../DateRangePicker';
import Select from '../Select';
import '../styles.scss';
import graphql from '@chaskiq/store/src/graphql/client';
import { SelectType } from '../SelectOptions';
import { ConversationsCounts } from '@chaskiq/store/src/graphql/queries';
import { CSVLink } from 'react-csv';
import Box from '@mui/material/Box';
import Input from '@chaskiq/components/src/components/forms/Input';
import { useDispatch } from 'react-redux';
import { errorMessage } from '@chaskiq/store/src/actions/status_messages';
import i18n from '../../../shared/FakeI18n';
import { FaFileExport } from 'react-icons/fa';
import Spinner from '../Spinner';

export default function TagChat({ app }) {
  const [loading, setLoading] = useState(false);
  const [toggleView, setToggleView] = useState(false);
  const [dateValues, setDateValues] = useState<any>([null, null]);
  const [resultData, setResultData] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [error, setError] = useState(false);
  let titleRef: any = React.createRef();
  const dispatch = useDispatch();

  const handleChangeView = () => {
    if (!loading) {
      setToggleView(!toggleView);
    }
  };

  const getConversationsCounts = () => {
    const start = dateValues[0];
    const end = dateValues[1];

    if (start != null && end != null) {
      if (titleRef.value == '' || titleRef.value == null) {
        //dispatch(errorMessage(i18n.t('graph.tag_error')));
        setError(true);
        return;
      } else {
        setError(false);
      }
      setLoading(true);
      graphql(
        ConversationsCounts,
        {
          appKey: app.key,
          type: titleRef.value,
          from: `${start.getFullYear()}-${
            start.getMonth() + 1
          }-${start.getDate()}`,
          to: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
          is_tag: true,
        },
        {
          success: (data) => {
            const rData = data.app.typeConversationsCounts;
            setResultData(rData);
            let myOptions = [];
            if (rData?.hourly?.length) {
              myOptions.push(SelectType.HOURLY);
            }
            if (rData?.daily?.length) {
              myOptions.push(SelectType.DAILY);
            }
            if (rData?.weekly?.length) {
              myOptions.push(SelectType.WEEKLY);
            }
            if (rData?.monthly?.length) {
              myOptions.push(SelectType.MONTHLY);
            }
            setOptions(myOptions);
            setSelectedOption(myOptions[0]);
            setLoading(false);
          },
          error: (err) => {
            setLoading(false);
            dispatch(errorMessage(i18n.t('graph.tag_error')));
            console.log('error', err);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (
      resultData != null &&
      selectedOption != null &&
      dateValues[0] != null &&
      dateValues[1] != null
    ) {
      if (selectedOption == SelectType.HOURLY) {
        setSelectedData(resultData.hourly);
      }
      if (selectedOption == SelectType.DAILY) {
        setSelectedData(resultData.daily);
      }
      if (selectedOption == SelectType.WEEKLY) {
        setSelectedData(resultData.weekly);
      }
      if (selectedOption == SelectType.MONTHLY) {
        setSelectedData(resultData.monthly);
      }
    }
  }, [selectedOption, dateValues, resultData]);
  return (
    <div className={`graph-container  ${loading ? 'loading' : ''}`}>
      <div className="graph-container-header">
        <div>
          <span>Total</span>
          <h5>{resultData?.total}</h5>
        </div>
        <div className="flex-container">
          {/* export file */}
          <div>
            {!loading && selectedData?.length ? (
              <Box sx={{ minWidth: 120 }}>
                <CSVLink
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '135px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  disabled={loading || selectedData?.length <= 0}
                  data={selectedData?.length > 0 ? selectedData : []}
                  filename={
                    selectedOption +
                    ' report ' +
                    new Date().toDateString() +
                    '.csv'
                  }
                  className="export-btn btn btn-secondary"
                  target="_blank"
                >
                  Export Data&nbsp;
                  <span style={{ color: '#fff' }}>
                    <FaFileExport />
                  </span>
                </CSVLink>
              </Box>
            ) : null}
          </div>
          <div className="view-change" onClick={handleChangeView}>
            <BsGrid3X3 />
          </div>
          <Input
            style={error ? { border: '1px solid red' } : {}}
            id="tag-input"
            type={'string'}
            ref={(ref) => (titleRef = ref)}
            placeholder={i18n.t('graph.enter_tag')}
            error={error}
            disabled={loading}
          />
          <DateRangePicker
            value={dateValues}
            setValue={setDateValues}
            disabled={loading}
          />
          <Select
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            disabled={loading}
          />
          <div>
            <button
              className="btn btn-primary"
              disabled={loading}
              style={{}}
              onClick={getConversationsCounts}
            >
              submit
            </button>
          </div>
        </div>
      </div>
      {/* <hr className='horizontal-line'/> */}
      <div className="graph-container-body">
        {loading ? (
          <Spinner />
        ) : toggleView ? (
          <div>
            <TableComponent data={selectedData} />
          </div>
        ) : (
          <GraphComponent data={selectedData} />
        )}
      </div>
    </div>
  );
}
