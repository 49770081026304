import ActionTypes, { ActionType } from '../constants/action_types';
import graphql from '../graphql/client';

import {
  CONVERSATIONS,
  CONVERSATION_WITH_LAST_MESSAGE,
} from '../graphql/queries';

import { playSound, appendMessage } from './conversation';

import { uniqBy } from 'lodash';

function checkDuplicates(
  oldConvo,
  newConvo,
  nextPage,
  dispatch,
  collectionMap
) {
  let mp = {};
  if (nextPage <= 1) {
    //it's the first page no duplicate by default
    oldConvo.forEach((c) => {
      mp[c.id] = true;
    });
    dispatch(dispatchMapUpdate(mp));
    return oldConvo;
  } else {
    mp = collectionMap;
    let uniqueNewConvo = [...newConvo];
    uniqueNewConvo.forEach((c, i) => {
      if (mp[c.id]) uniqueNewConvo.splice(i, 1);
      else mp[c.id] = true;
    });
    dispatch(dispatchMapUpdate(mp));
    return uniqueNewConvo;
  }
}

export function getConversations(options, cb) {
  const { page } = options;
  return (dispatch, getState) => {
    const { sort, filter, agentId, meta, tag, term, exact } =
      getState().conversations;

    const nextPage = page || meta.next_page || 1;

    dispatch(dispatchDataUpate({ loading: true, loading_unread: true }));
    graphql(
      CONVERSATIONS,
      {
        appKey: getState().app.key,
        page: nextPage,
        sort: sort,
        filter: filter,
        agentId: agentId,
        tag: tag,
        term: term,
        email: options.email,
        from: options.from,
        to: options.to,
        all_apps: true,
        type: options.isExact ? options.isExact : exact ? 'equal' : 'like',
      },
      {
        success: (data) => {
          const conversations = data.app.conversations;
          const newCollection =
            nextPage > 1
              ? checkDuplicates(
                  getState().conversations.collection,
                  conversations.collection,
                  nextPage,
                  dispatch,
                  getState().conversations.renderedCollection
                )
              : checkDuplicates(
                  conversations.collection,
                  [],
                  nextPage,
                  dispatch,
                  getState().conversations.renderedCollection
                );
          const newData = {
            collection:
              nextPage > 1
                ? getState().conversations.collection.concat(newCollection)
                : conversations.collection,
            meta: conversations.meta,
            loading: false,
            loading_unread: false,
            conversations_unread_count: data.app.unreadConversations,
            total_count: conversations.meta.total_count,
          };
          dispatch(dispatchGetConversations(newData));

          if (cb) cb();
        },
        error: (e) => {
          console.log('error', e);
        },
      }
    );
  };
}

export function updateConversationReadFromOtherApp(
  lastMsgReadAt,
  conversationKey
) {
  return (_, getState) => {
    let newCollection = getState().conversations.collection;
    for (var i = 0, len = newCollection?.length; i < len; i++) {
      if (newCollection[i].key === conversationKey) {
        newCollection[i].lastMessage = {
          ...newCollection[i].lastMessage,
          readAt: lastMsgReadAt,
        };
      }
    }
  };
}

export function appendConversation(data) {
  let activeIdx = -1;
  return (dispatch, getState) => {
    const conversation = getState().conversations.collection.find(
      (o) => o.key === data.conversationKey
    );

    let newMessages = null;

    // add new or update existing
    if (!conversation) {
      const fakeConvo = {
        appKey: data.appKey,
        assignee: !data.assignee
          ? null
          : {
              id: data.assignee.id,
              email: data.assignee.email,
              avatarUrl: data.assignee.avatarUrl,
              isBot: data.assignee.isBot,
            },
        createdAt: data.createdAt,
        firstAgentReply: data.firstAgentReply,
        id: data.conversationId,
        key: data.conversationKey,
        lastMessage: data,
        latestUserVisibleCommentAt: data.latestUserVisibleCommentAt,
        mainParticipant: {
          ...data.appUser,
          kind: 'lead',
          properties: { name: data.appUser.displayName },
        },
        priority: null,
        readAt: data.readAt,
        state: data.state,
        subject: null,
        tagList: data.tagList,
        whatsappId: data.whatsappId,
      };

      newMessages = [fakeConvo].concat(getState().conversations.collection);
      dispatch(appendConversationDispatcher(newMessages, true));
    } else {
      // To make the conversation appear at the top
      let editedConversations = [...getState().conversations.collection];
      let updatedConversation;
      for (var i = 0; i < getState().conversations.collection.length; i++) {
        if (editedConversations[i].key === data.conversationKey) {
          updatedConversation = editedConversations[i];
          updatedConversation.lastMessage = data;
          if (i !== 0) {
            // if not at the top
            editedConversations.splice(i, 1);
            editedConversations.unshift(updatedConversation);
          }
          break;
        }
      }

      const newConversations = getState().conversations.collection.map((o) => {
        if (o.key === data.conversationKey) {
          o.lastMessage = data;
          return o;
        } else {
          return o;
        }
      });

      for (let i = 0; i < getState().openConversations.data.length; i++) {
        if (conversation.key === getState().openConversations.data[i].key) {
          activeIdx = i;
          dispatch(appendMessage(data, () => {}, activeIdx));
          break;
        }
      }

      dispatch(appendConversationDispatcher(editedConversations, false));
    }

    if (
      getState().openConversations.data[activeIdx]?.key !== data.conversationKey
    ) {
      if (data.appUser.kind !== 'agent') {
        playSound();
      }
    }
  };
}

export function updateConversationsData(data, cb) {
  return (dispatch, _getState) => {
    dispatch(dispatchDataUpate(data));
    cb && cb();
  };
}

export function updateConversationItem(data) {
  return (dispatch, _getState) => {
    dispatch({
      type: ActionTypes.UpdateConversationItem,
      data: data,
    });
  };
}

export function removeConversation(data) {
  return (dispatch, getState) => {
    let conversations = [...getState().conversations.collection];
    const oldLength = conversations.length;
    conversations = conversations.filter((c) => c.id !== data);
    dispatch(
      dispatchRemoveConversation(
        conversations,
        oldLength > conversations.length
      )
    );
  };
}

export function clearConversations(data) {
  return (dispatch, _getState) => {
    dispatch({
      type: ActionTypes.ClearConversations,
      data: data,
    });
  };
}

function appendConversationDispatcher(data, append) {
  // TODO: the data here is filteres to get uniq array by conv key
  // but the real deal solution might be a redux queue to append sequencially
  // https://redux-loop.js.org/docs/recipes/ActionQueue.html
  return {
    type: ActionTypes.AppendConversation,
    data: uniqBy(data, 'key'),
    append: append,
  };
}

function dispatchGetConversations(data) {
  return {
    type: ActionTypes.GetConversations,
    data: data,
  };
}

function dispatchDataUpate(data) {
  return {
    type: ActionTypes.UpdateConversations,
    data: data,
  };
}

function dispatchMapUpdate(data) {
  return {
    type: ActionTypes.UpdateConversationsMap,
    data: data,
  };
}

function dispatchRemoveConversation(data, isDecremented) {
  return {
    type: ActionTypes.removeConversation,
    data: data,
    isDecremented,
  };
}

export function dispatchConversationsUnreadCount(count) {
  return {
    type: ActionTypes.ConversationsUnreadCount,
    data: count,
  };
}

// Reducer
export default function reducer(
  state = {
    meta: {},
    sort: 'newest',
    filter: 'opened',
    loading: false,
    collection: [],
    renderedCollection: {},
    agentId: null,
    tag: null,
    term: null,
    appended: 0,
    loading_unread: false,
    conversations_unread_count: 0,
    total_count: 0,
  },
  action: ActionType = {}
) {
  switch (action.type) {
    case ActionTypes.UPDATE_TOTAL_COUNT: {
      return {
        ...state,
        total_count: action.data,
      };
    }
    case ActionTypes.GetConversations: {
      const test = Object.assign({}, state, action.data, {
        appended: 0,
      });
      return test;
    }

    case ActionTypes.UpdateConversations: {
      return Object.assign({}, state, action.data);
    }

    case ActionTypes.IncreaseAppendedCount: {
      return { ...state, appended: state.appended + 1 };
    }

    case ActionTypes.removeConversation: {
      return Object.assign({}, state, {
        collection: action.data,
        total_count:
          // @ts-ignore
          state.total_count > 0 && action.isDecremented
            ? state.total_count - 1
            : state.total_count,
      });
    }
    case ActionTypes.AppendConversation: {
      return Object.assign({}, state, {
        collection: action.data,
        // @ts-ignore
        total_count: action.append ? state.total_count + 1 : state.total_count,
      });
    }

    case ActionTypes.ClearConversations: {
      return Object.assign({}, state, { collection: [], total_count: 0 });
    }

    case ActionTypes.ConversationsUnreadCount: {
      return { ...state, conversations_unread_count: action.data };
    }

    case ActionTypes.UpdateConversationsMap: {
      return { ...state, renderedCollection: action.data };
    }

    case ActionTypes.UpdateConversationItem: {
      return {
        ...state,
        collection: state.collection.map((item) =>
          item.id === action.data.id ? { ...item, ...action.data } : item
        ),
      };
    }

    default:
      return state;
  }
}
