import React from 'react';

function TableComponent({ data = [] }) {
  return (
    <table className="table" id="export-from-data-to-excel">
      <thead>
        <tr>
          <td style={{ fontWeight: 'bold' }} scope="col">
            Date
          </td>
          <td style={{ fontWeight: 'bold' }} scope="col">
            Count
          </td>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.length &&
          data.map((item, idx) => (
            <tr key={idx}>
              <td>{item.name}</td>
              <td>{item.valueLabel ? item.valueLabel : item.value}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default TableComponent;
