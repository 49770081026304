const calendly = '/assets/images/logos/calendly.png';
const facebook = '/assets/images/logos/facebook.png';
const pipedrive = '/assets/images/logos/pipedrive.png';
const whatsapp = '/assets/images/logos/whatsapp.png';
const clearbit = '/assets/images/logos/clearbit.png';
const gumroad = '/assets/images/logos/gumroad.png';
const paddle = '/assets/images/logos/paddle.png';
const openai = '/assets/images/logos/openai.png';
const reveniu = '/assets/images/logos/reveniu.png';
const stripe = '/assets/images/logos/stripe.png';

const fullcontact = '/assets/images/logos/fullcontact.png';
const slack = '/assets/images/logos/slack.png';
const zoom = '/assets/images/logos/zoom.png';
const dailytics = '/assets/images/logos/dailytics.png';
const helpscout = '/assets/images/logos/helpscout.png';
const twilio = '/assets/images/logos/twilio.png';
const dialogflow = '/assets/images/logos/dialogflow.png';
const messenger = '/assets/images/logos/messenger.png';
const twitter = '/assets/images/logos/twitter.png';
const openAi = '/assets/images/logos/open_ai.png';
const telegram = '/assets/images/logos/telegram.png';
const zapier = '/assets/images/logos/zapier.png';
const dialog360 = '/assets/images/logos/dialog360.png';
const vonage = '/assets/images/logos/vonage.png';
const messagebird = '/assets/images/logos/messagebird.png';

export default {
  messagebird,
  vonage,
  dialog360,
  telegram,
  zapier,
  calendly,
  facebook,
  pipedrive,
  whatsapp,
  clearbit,
  fullcontact,
  slack,
  zoom,
  dailytics,
  helpscout,
  stripe,
  twilio,
  dialogflow,
  messenger,
  twitter,
  openAi,
  gumroad,
  paddle,
  openai,
  reveniu,
};
