import ActionTypes, { ActionType } from '../constants/action_types';

import React from 'react';
import { toast } from 'react-toastify';

// Intial state
const notificationsState = {
  notifications: [],
  show: false,
};

// Actions
export const handleMessageNotification = (
  name: string,
  message: string,
  notificationKey: string
) => {
  return (dispatch, getState) => {
    dispatch(
      handle_message_notification({
        notificationKey: notificationKey,
        name:
          getState()?.navigation?.current_page !== 'Conversations' &&
          getState()?.navigation?.current_section !== 'Conversations'
            ? name
            : null,
        message:
          getState()?.navigation?.current_page !== 'Conversations' &&
          getState()?.navigation?.current_section !== 'Conversations'
            ? message
            : null,
      })
    );
  };
};

const handle_message_notification = (data) => {
  return {
    type: ActionTypes.SetNewNotification,
    data: data,
  };
};

// View for notify
function Display(props) {
  const { name, msg } = props;
  return (
    <div>
      <h3 style={{ fontWeight: 'bolder' }}>{name}</h3>
      <p>{msg}</p>
    </div>
  );
}

// Reducer
export default function reducer(
  state = notificationsState,
  action: ActionType = {}
) {
  switch (action.type) {
    case ActionTypes.SetNewNotification:
      if (action.data.name && action.data.message)
        toast.info(
          <Display name={action.data.name} msg={action.data.message} />
        );
      let newArr = [...state?.notifications];
      newArr.push(action?.data?.notificationKey);
      return { ...state, notifications: newArr, show: true };
    default:
      return state;
  }
}
