import React, { useEffect } from 'react';
import ChaskiqMessengerEncrypted from '../messenger/src/client_messenger/messengerEncrypted';
import { useParams } from 'react-router-dom';
// import ChaskiqMessengerEncrypted from '../../packages/messenger/src/client_messenger/messengerEncrypted';

function UserMessenger() {
  const { app_id } = useParams();
  const hostname = window.location.hostname;
  const port = window.location.port ? ':' + window.location.port : '';
  const secure = window.location.protocol === 'https:';
  const httpProtocol = window.location.protocol;
  const wsProtocol = secure ? 'wss' : 'ws';
  const hostnamePort = `${hostname}${port}`;

  useEffect(() => {
    new ChaskiqMessengerEncrypted({
      domain: `${httpProtocol}//${hostnamePort}`,
      ws: `${wsProtocol}://${hostnamePort}/cable`,
      app_id: app_id,
      data: 'YOUR_ENCRYPTED_JWE_DATA',
      lang: 'USER_LANG_OR_DEFAULTS_TO_BROWSER_LANG',
      wrapperId: 'therootofthechaskiqmessenger2',
    });
  }, []);

  return (
    <div id="therootofthechaskiqmessenger2">
      {/* <ChaskiqMessengerEncrypted
        lang="USER_LANG_OR_DEFAULTS_TO_BROWSER_LANG"
        app_id={app_id}
        data="YOUR_ENCRYPTED_JWE_DATA"
        domain={`${httpProtocol}//${hostnamePort}`}
        ws={`${wsProtocol}://${hostnamePort}/cable`}
        wrapperId="therootofthechaskiqmessenger2"
      /> */}
    </div>
  );
}

export default UserMessenger;
