import React, { useEffect, useState } from 'react';
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md';
import { AiFillTags } from 'react-icons/ai';
import FormDialog from '@chaskiq/components/src/components/FormDialog';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ReportTypes from './ReportTypes';
import {
  createReportMenuItem,
  getReportMenuItemById,
  updateReportMenuItemById,
  deleteReportMenuItem,
} from '@chaskiq/store/src/actions/reportMenuItems';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MenuItemForm = ({
  dispatch,
  isDialogOpen,
  setDialogOpen,
  isUpdate = false,
  selectedMenuItem = undefined,
  reportMenuItems,
  app,
}) => {
  const history = useHistory();
  // form data
  const [title, setTitle] = useState('');
  const [numerator, setNumerator] = useState(null);
  const [denominator, setDenominator] = useState(null);
  const [tagName, setTagName] = useState('');

  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [hasTag, setHasTag] = useState(false);

  useEffect(() => {
    if (!isUpdate) {
      setHasTag(false);
      resetStates();
    }
    if (isUpdate && isDialogOpen) {
      dispatch(getReportMenuItemById(selectedMenuItem));
    }

    setOptions(Object.values(ReportTypes));
  }, [isDialogOpen]);

  useEffect(() => {
    const { item } = reportMenuItems;
    if (isUpdate && item != null && item != undefined) {
      const num = Object.values(ReportTypes)[item.firstParameter];
      const deno = Object.values(ReportTypes)[item.secondParameter];
      setTitle(item.properties.title);
      disableOption(null, num);
      disableOption(null, deno);
      if (num == ReportTypes['TAG'] || deno == ReportTypes['TAG']) {
        setTagName(item.properties.tag);
        setHasTag(true);
      } else {
        setTagName('');
        setHasTag(false);
      }
      setNumerator(num);
      setDenominator(deno);
    }
  }, [reportMenuItems.item]);

  const checkIfHasTag = (newValue) => {
    if (newValue?.index == ReportTypes['TAG'].index) {
      setHasTag(true);
    }
  };

  const disableOption = (oldValue, option) => {
    const selectedOpIdx = options.find((o) => o.index == option.index).index;
    const newOptions = options;
    for (let i = 0; i < newOptions.length; i++) {
      if (newOptions[i].disabled && oldValue?.index != i) continue;
      if (i == selectedOpIdx) {
        newOptions[i].disabled = true;
      } else {
        newOptions[i].disabled = false;
        if (i == ReportTypes['TAG'].index) {
          setTagName('');
          setHasTag(false);
        }
      }
    }
    setOptions(newOptions);
  };

  const restOptions = () => {
    const newOptions = options;
    for (let i = 0; i < newOptions.length; i++) {
      newOptions[i].disabled = false;
    }
    setOptions(newOptions);
  };

  const isDataValid = () => {
    const _errors = {};
    if (!numerator) {
      const errMsg = 'Numerator is required';
      _errors['numerator'] = errMsg;
    }

    if (!denominator) {
      const errMsg = 'Denominator is required';
      _errors['denominator'] = errMsg;
    }

    if (hasTag && tagName.length < 1) {
      const errMsg = 'Tag name is required';
      _errors['tagName'] = errMsg;
    }

    setErrors(_errors);
    return !Object.values(_errors).length;
  };

  const renderError = (errMsg) => {
    return (
      <div
        className="flex items-center justify-space w-full px-2"
        style={{ color: 'red' }}
      >
        <small>{errMsg}</small>
      </div>
    );
  };

  const resetStates = () => {
    setTitle('');
    setNumerator(null);
    setDenominator(null);
    setTagName('');
    setErrors({});
    restOptions();
  };

  const handleCloseModal = () => {
    resetStates();
    setDialogOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isDataValid()) {
      const menuItem = {
        firstParameter: numerator.index,
        secondParameter: denominator.index,
        title: title,
        tag: tagName,
      };
      if (isUpdate) {
        dispatch(
          updateReportMenuItemById(menuItem, () => {
            handleCloseModal();
            history.push(
              `/apps/${app.key}/reports/custom-reports/${reportMenuItems.item.id}/${menuItem.title}`
            );
          })
        );
      } else {
        dispatch(
          createReportMenuItem(menuItem, (item) => {
            handleCloseModal();
            history.push(
              `/apps/${app.key}/reports/custom-reports/${item.id}/${item.properties.title}`
            );
          })
        );
      }
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(
      deleteReportMenuItem(() => {
        handleCloseModal();
        history.push(`/apps/${app.key}/reports/overview`);
      })
    );
  };
  return (
    <FormDialog
      open={isDialogOpen}
      handleClose={handleCloseModal}
      formComponent={
        <form onSubmit={handleSubmit}>
          <h3>Add New Custom Report</h3>
          <div className="flex items-center justify-space w-full border rounded-md px-2 py-2 mx-2 my-2">
            <MdOutlineDriveFileRenameOutline size={16} />
            <input
              className="w-full ml-2 bg-transparent active:outline-none focus:outline-none text-sm py-1"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value?.trim());
              }}
              placeholder={'Title'}
            />
          </div>
          {hasTag && (
            <div className="flex items-center justify-space w-full border rounded-md px-2 py-2 mx-2 my-2">
              <AiFillTags size={16} />
              <input
                className="w-full ml-2 bg-transparent active:outline-none focus:outline-none text-sm py-1"
                value={tagName}
                onChange={(e) => {
                  setTagName(e.target.value?.trim());
                }}
                placeholder={'Tag Name'}
              />
            </div>
          )}
          {errors['tagName'] && renderError(errors['tagName'])}
          <div className="flex items-center justify-space w-full border rounded-md px-2 py-2 mx-2 my-2">
            <Autocomplete
              id="combo-box-apps"
              value={numerator}
              isOptionEqualToValue={(option, value) =>
                option.index === value.index
              }
              // isOptionEqualToValue={true}
              onChange={(event, newValue) => {
                checkIfHasTag(newValue);
                disableOption(numerator, newValue);
                setNumerator(newValue);
              }}
              getOptionLabel={(option) => option.title}
              getOptionDisabled={(option) => !!option.disabled}
              options={options}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Numerator" variant="outlined" />
              )}
            />
            <span className="operator">/</span>
            <Autocomplete
              id="combo-box-apps"
              value={denominator}
              onChange={(event, newValue) => {
                checkIfHasTag(newValue);
                disableOption(denominator, newValue);
                setDenominator(newValue);
              }}
              getOptionLabel={(option) => option.title}
              getOptionDisabled={(option) => !!option.disabled}
              options={options}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Denominator" variant="outlined" />
              )}
            />
            <span className="operator">%</span>
          </div>
          {errors['numerator'] && renderError(errors['numerator'])}
          {errors['denominator'] && renderError(errors['denominator'])}

          <button
            type="submit"
            style={{ float: 'right', borderRadius: '.8rem' }}
            className="-ml-px relative inline-flex items-center px-4 py-2 my-2 border
                border-gray-300 dark:border-white dark:bg-black dark:text-white 
                text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 
                hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue 
                focus:border-blue-300 active:bg-gray-100 active:text-gray-700 
                transition ease-in-out duration-150"
          >
            <span className="ml-2">{isUpdate ? 'Update' : 'Submit'}</span>
          </button>
          {isUpdate && (
            <button
              type="button"
              style={{
                float: 'left',
                background: 'red',
                borderRadius: '.8rem',
              }}
              onClick={handleDelete}
              className="-ml-px relative inline-flex items-center px-4 py-2 my-2 border
              border-gray-300 dark:border-white dark:bg-black dark:text-white 
              text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 
              hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue 
              focus:border-blue-300 active:bg-gray-100 active:text-gray-700 
              transition ease-in-out duration-150"
            >
              <span className="ml-2">Delete</span>
            </button>
          )}
        </form>
      }
    ></FormDialog>
  );
};

function mapStateToProps(state) {
  const { reportMenuItems, app } = state;
  return {
    reportMenuItems,
    app,
  };
}

export default withRouter(connect(mapStateToProps)(MenuItemForm));
