import React, { useEffect, useState } from 'react';
import graphql from '@chaskiq/store/src/graphql/client';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SelectType } from '../SelectOptions';
import { ConversationsCounts } from '@chaskiq/store/src/graphql/queries';
import ReportGraph from '../ReportGraph';
import { getGraphData } from '@chaskiq/store/src/actions/reportMenuItems';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const DynamicMenuItem = ({ dispatch, app }) => {
  const [loading, setLoading] = useState(false);
  const [toggleView, setToggleView] = useState(false);
  const [dateValues, setDateValues] = useState<any>([null, null]);
  const [resultData, setResultData] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    setLoading(false);
    setToggleView(false);
    setDateValues([null, null]);
    setResultData(null);
    setOptions([]);
    setSelectedOption(null);
    setSelectedData([]);
  }, [history.location.pathname]);

  const handleSubmit = () => {
    const start = dateValues[0];
    const end = dateValues[1];
    if (start != null && end != null) {
      setLoading(true);
      const opts = {
        id: parseInt(id),
        from: `${start.getFullYear()}-${
          start.getMonth() + 1
        }-${start.getDate()}`,
        to: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
      };
      dispatch(
        getGraphData(opts, (rData) => {
          if (rData) {
            setResultData(rData);
            let myOptions = [];
            if (rData?.hourly?.length) {
              myOptions.push(SelectType.HOURLY);
            }
            if (rData?.daily?.length) {
              myOptions.push(SelectType.DAILY);
            }
            if (rData?.weekly?.length) {
              myOptions.push(SelectType.WEEKLY);
            }
            if (rData?.monthly?.length) {
              myOptions.push(SelectType.MONTHLY);
            }
            setOptions(myOptions);
            setSelectedOption(myOptions[0]);
          } else {
            setOptions([]);
            setSelectedOption(null);
          }

          setLoading(false);
        })
      );
    }
  };

  return (
    <>
      <ReportGraph
        states={{
          loading: loading,
          toggleView: toggleView,
          resultData: resultData,
          selectedOption: selectedOption,
          dateValues: dateValues,
          selectedData: selectedData,
          options: options,
        }}
        setters={{
          setToggleView: setToggleView,
          setSelectedData: setSelectedData,
          setDateValues: setDateValues,
          setSelectedOption: setSelectedOption,
        }}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(DynamicMenuItem));
