import React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import I18n from '../../shared/FakeI18n';

import { last } from 'lodash';
import Moment from 'react-moment';
import { toCamelCase } from '@chaskiq/components/src/utils/caseConverter';
import { RiUserFill } from 'react-icons/ri';
import Progress from '@chaskiq/components/src/components/Progress';
import Button from '@chaskiq/components/src/components/Button';
import tw from 'twin.macro';
import { IoLogoWhatsapp } from 'react-icons/io';
import ErrorBoundary from '@chaskiq/components/src/components/ErrorBoundary';

import { MoreIcon } from '@chaskiq/components/src/components/icons';

import FilterMenu from '@chaskiq/components/src/components/FilterMenu';
import theme from '@chaskiq/components/src/components/textEditor/theme';
import themeDark from '@chaskiq/components/src/components/textEditor/darkTheme';
import EditorContainer from '@chaskiq/components/src/components/textEditor/editorStyles';
import DraftRenderer from '@chaskiq/components/src/components/textEditor/draftRenderer';
import styled from '@emotion/styled';

import { toggleDrawer } from '@chaskiq/store/src/actions/drawer';

import {
  getConversation,
  setLoading,
  clearConversation,
} from '@chaskiq/store/src/actions/conversation';

const EditorContainerMessageBubble = styled(EditorContainer)`
  //display: flex;
  //justify-content: center;

  // this is to fix the image on message bubbles
  .aspect-ratio-fill {
    display: none;
  }
  .aspectRatioPlaceholder.is-locked .graf-image {
    position: inherit;
  }
`;

const BgContainer = styled.div`
  /*background-image: radial-gradient(currentColor 2px, transparent 2px),
    radial-gradient(currentColor 2px, transparent 2px);
  background-size: calc(20 * 2px) calc(20 * 2px);
  background-position: 0 0, calc(10 * 2px) calc(10 * 2px);*/
  background-image: url('/assets/images/memphis-mini.png');
  /* background-size: calc(40px) calc(40px); */
  background-position: 0px 0px, calc(20px) calc(20px);
`;

type MessageItemType = {
  userOrAdmin: 'user' | 'admin';
  privateNote: boolean;
};

const MessageItem = styled.div<MessageItemType>`
  word-break: break-word;
  ${
    (props) =>
      props.userOrAdmin === 'user'
        ? tw`bg-white text-green-500`
        : props.privateNote
        ? tw`bg-yellow-300 text-black`
        : tw`bg-gray-700 text-white`

    // `background: linear-gradient(45deg,#48d79b,#1dea94f2);` :
    // `background: linear-gradient(45deg,#202020,#000000e6)`
  }
`;

function Conversation({
  dispatch,
  conversation,
  match,
  app,
  currentAppKey,
  current_user,
  drawer,
  events,
  toggleFixedSidebar,
  fixedSidebarOpen,
}) {
  const overflow = React.useRef<HTMLDivElement>(null);

  const matchId = match ? match.params.id : null; //key of conversation

  const messagesLength = conversation.collection
    ? conversation.collection.length
    : null;

  const { mainParticipant } = conversation;

  const [scrolling, setScrolling] = React.useState(false);

  const [conversationPartSelected, setConversationPartSelected] =
    React.useState(false);

  React.useEffect(() => {
    if (!matchId) return;

    dispatch(
      clearConversation(() => {
        getMessages(scrollToLastItem);
      })
    );
  }, [matchId]);

  React.useEffect(() => {
    if (!scrolling) {
      scrollToLastItem();
    }
    setScrolling(false);
  }, [messagesLength]);

  const handleScroll = (e) => {
    if (conversation.loading) return;
    const element = e.target;
    if (element.scrollTop === 0) {
      // on top
      if (conversation.meta.next_page && !conversation.loading) {
        setScrolling(true);
        getMessages((item) => {
          scrollToItem(item);
        });
      }
    }
  };

  const scrollToItem = (item) => {
    if (item) {
      const q: HTMLDivElement = document.querySelector(`#message-id-${item}`);
      overflow.current.scrollTop = q.offsetHeight;
    } else {
      scrollToLastItem();
    }
  };

  const scrollToLastItem = () => {
    if (!overflow.current) return;
    overflow.current.scrollTop = overflow.current.scrollHeight;
  };

  const getMessages = (cb) => {
    const opts = {
      id: matchId,
      appKey: currentAppKey,
    };

    const lastItem: any = last(conversation.collection);

    dispatch(setLoading(true));
    dispatch(
      getConversation(opts, () => {
        // this.getMainUser(this.state.conversation.mainParticipant.id)
        // TODO: this will scroll scroll to last when new items
        // are added on pagination (scroll up)!
        cb && cb(lastItem ? lastItem.id : null);
      })
    );
  };

  const renderMessage = (o, userOrAdmin) => {
    const message = o;
    const messageContent = o.message;
    const key = `conversation-${conversation.key}-message-${o.key}`;

    const content = messageContent.serializedContent ? (
      <DraftRenderer
        key={key}
        raw={JSON.parse(messageContent.serializedContent)}
        html={messageContent.htmlContent}
      />
    ) : (
      <div
        key={key}
        dangerouslySetInnerHTML={{
          __html: messageContent.htmlContent,
        }}
      />
    );

    let textClass = userOrAdmin === 'admin' ? 'text-gray-100' : '';
    const flow = userOrAdmin === 'admin' ? 'flex-row-reverse' : '';
    const avatarM = userOrAdmin === 'admin' ? 'ml-3' : 'mr-3';
    if (message.privateNote) {
      textClass = 'text-gray-900';
    }

    const isAdmin = userOrAdmin === 'admin';

    return (
      <div
        id={`message-id-${message.key}`}
        className={`flex items-start py-2 text-sm ${flow}`}
        key={`conversations-messages/${message.key}`}
      >
        <MessageItem
          userOrAdmin={userOrAdmin}
          privateNote={message.privateNote}
          className={`
            shadow 
            sm:rounded-lg
            flex-1 
            p-3 
            max-w-full
          `}
        >
          <div className="flex justify-between pb-4">
            <div className="flex items-center">
              <span className={`font-bold ${textClass}`}>
                {message.appUser.displayName}
              </span>
            </div>
            <span className={`flex items-center text-xs ${textClass}`}>
              <Moment fromNow ago>
                {message.createdAt}
              </Moment>

              <span className={textClass}>
                {' - '}
                {message.readAt ? (
                  <span>{I18n.t('conversation.messages.seen')}</span>
                ) : message.privateNote ? (
                  I18n.t('conversation.messages.note')
                ) : (
                  <span>{I18n.t('conversation.messages.not_seen')}</span>
                )}
              </span>

              {!isAdmin && messageContent.serializedContent && (
                <FilterMenu
                  options={[
                    {
                      title: I18n.t('common.actions'),
                      onClick: () => {
                        setConversationPartSelected(messageContent);
                      },
                    },
                  ]}
                  value={null}
                  filterHandler={(e) => e.onClick && e.onClick()}
                  triggerButton={(handler) => (
                    <Button variant="icon" onClick={handler} className="ml-2">
                      <MoreIcon className="text-gray-400" />
                    </Button>
                  )}
                  position={'right'}
                  origin={'bottom-0'}
                />
              )}
            </span>
          </div>

          <EditorContainerMessageBubble>{content}</EditorContainerMessageBubble>
        </MessageItem>
      </div>
    );
  };

  const renderEventBlock = (o) => {
    const message = o;
    const messageContent = o.message;

    return (
      <div
        id={`message-id-${message.id}`}
        className={'flex items-start py-2 text-sm'}
        key={`conversations-messages/${message.id}`}
      >
        <div
          className={`bg-yellow-300
          flex 
          overflow-hidden p-2 
          rounded-md mx-auto`}
        >
          <div className="flex flex-col justify-between">
            <span className={'text-xs text-center'}>
              <Moment fromNow ago>
                {message.createdAt}
              </Moment>

              <span>
                {' - '}
                {message.readAt ? (
                  <span>{I18n.t('conversation.messages.seen')}</span>
                ) : (
                  <span>{I18n.t('conversation.messages.not_seen')}</span>
                )}
              </span>
            </span>

            <p className="text-md text-center font-bold">
              {messageContent.action}{' '}
              {messageContent.data.name || messageContent.data.email}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleUserSidebar = () => {
    dispatch(toggleDrawer({ userDrawer: !drawer.userDrawer }));
  };

  return (
    <BgContainer className="flex-1 flex flex-col overflow-hidden-- h-screen">
      <div
        className="border-b flex px-6 py-3 items-center flex-none bg-white dark:bg-gray-800 dark:border-gray-700"
        style={{ height: '63px' }}
      >
        <div className="flex items-center">
          {conversation.mainParticipant &&
          !fixedSidebarOpen &&
          conversation?.whatsappId != null ? (
            <div className="h-9 w-9 rounded-full mr-2 cursor-pointer flex justify-center items-center">
              <IoLogoWhatsapp
                color="#33D952"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          ) : (
            <div className="h-9 w-9 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-gray-600">
              <RiUserFill color="white" />
            </div>
          )}

          <h3
            className="mb-1 text-grey-darkest hidden md:flex 
            flex-col justify-center items-start"
          >
            {conversation.subject && (
              <span className="font-bold text-sm">{conversation.subject}</span>
            )}

            <span className="flex space-x-1 text-xs">
              <span>{I18n.t('conversation.with')}</span>
              <span
                className="font-extrabold hover:text-underline"
                onClick={toggleFixedSidebar}
                // onClick={handleUserSidebar}
              >
                {conversation.mainParticipant &&
                  conversation.mainParticipant.displayName}
              </span>
            </span>
          </h3>
        </div>
      </div>

      <div
        ref={overflow}
        className="overflow-y-scroll"
        onScroll={handleScroll}
        style={{
          height: 'calc(100vh - 220px)',
        }}
      >
        <div className="flex flex-col-reverse px-6 py-4">
          <ErrorBoundary>
            {conversation &&
              conversation.collection &&
              conversation.collection.map((message) => {
                const isReplied = message.message.state === 'replied';
                const userOrAdmin =
                  !isReplied &&
                  message.appUser &&
                  message.appUser.kind === 'agent'
                    ? 'admin'
                    : 'user';

                return (
                  <MessageItemWrapper
                    key={`message-item-${conversation.key}-${message.key}`}
                    data={message}
                    events={events}
                    conversation={conversation}
                  >
                    <ThemeProvider
                      theme={
                        userOrAdmin === 'admin'
                          ? message.privateNote
                            ? theme
                            : themeDark
                          : theme
                      }
                    >
                      {message.message.blocks ? (
                        <RenderBlocks
                          conversation={conversation}
                          message={message}
                          app={app}
                          dispatch={dispatch}
                        />
                      ) : message.message.action ? (
                        renderEventBlock(message)
                      ) : (
                        renderMessage(message, userOrAdmin)
                      )}
                    </ThemeProvider>
                  </MessageItemWrapper>
                );
              })}

            {conversation.loading && (
              <div className="m-2">
                <Progress size={4} />
              </div>
            )}
          </ErrorBoundary>
        </div>
      </div>
    </BgContainer>
  );
}

function MessageItemWrapper({ conversation, data, events, children }) {
  React.useEffect(() => {
    // mark as read on first render
    setTimeout(sendRead, 300);
  }, []);

  function sendRead() {
    if (!data.readAt) {
      events &&
        events.perform(
          'receive_conversation_part',
          Object.assign(
            {},
            {
              conversation_key: conversation.key,
              message_key: data.key,
            },
            { email: data.email }
          )
        );
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
}

function RenderBlocks({ message, app, conversation, dispatch }) {
  const { data, blocks } = toCamelCase(message).message;

  const schema = blocks.schema;
  // will update package
  // const updatePackage = (data, cb) => {
  //   // for now the only supported action for agent facing pkg will be the url link

  //   if (data.field.action.type === 'url') {
  //     return window.open(data.field.action.url, '_blank');
  //   }

  //   const params = {
  //     id: blocks.appPackage,
  //     appKey: app.key,
  //     hooKind: data.field.action.type,
  //     ctx: {
  //       conversation_key: conversation.key,
  //       field: data.field,
  //       definitions: [data.field.action],
  //       location: 'inbox',
  //       values: data.values,
  //     },
  //   };

  //   getPackage(params, 'conversation', (data) => {
  //     const definitions = data.app.appPackage.callHook.definitions;
  //     const newMessage = message;
  //     newMessage.message.blocks.schema = definitions;
  //     dispatch(appendConversation(newMessage));
  //     cb && cb();
  //   });
  // };

  const renderBlockRepresentation = () => {
    // TODO: display labels, schema buttons
    let output = null;
    switch (blocks.type) {
      case 'app_package':
        output = (
          <div>
            <div
              className="text-gray-800 text-xs
            font-bold uppercase tracking-wide"
            >
              <div
                className="inline-flex items-baseline px-2.5 py-0.5 rounded-full
            text-xs font-light bg-green-100 text-green-800 md:mt-2 lg:mt-0"
              >
                <span>{blocks.appPackage}</span>
              </div>
            </div>

            <br />

            {/* <DefinitionRenderer
              schema={schema}
              values={blocks.values}
              updatePackage={updatePackage}
              disabled={true}
            /> */}
          </div>
        );
        break;
      case 'ask_option':
        output = <p>ask option</p>;
        break;
      case 'data_retrieval':
        output = <p>data retrieval</p>;
        break;
      default:
        null;
    }

    return (
      <div
        style={{
          opacity: 0.96,
        }}
        className={`
        w-full
        bg-white
        dark:bg-black
        dark:text-white
        dark:border-gray-900
        opacity-75
        border
        border-gray-400
        text-gray-600
        shadow-lg
        flex 
        overflow-hidden p-2 
        rounded-md mx-auto
        `}
      >
        <div className="w-full flex flex-col justify-between">{output}</div>
      </div>
    );
  };

  if (blocks.type === 'app_package') {
    // (o.message.state !== 'replied') {

    return (
      <div
        id={`message-id-${message.id}`}
        className={'flex items-start py-2 text-sm'}
        key={`conversations-messages/${message.id}`}
      >
        {renderBlockRepresentation()}
      </div>
    );
  }

  const item = message.message.data;
  if (!item) {
    return (
      <p className="text-sm leading-5 font-medium text-gray-500 py-2 flex justify-center">
        <a
          href="#"
          className="relative inline-flex items-center rounded-full border border-gray-400 px-3 py-0.5 text-sm bg-white"
        >
          <span className="absolute flex-shrink-0 flex items-center justify-center">
            <span
              className="h-1.5 w-1.5 rounded-full bg-yellow-200 border border-black"
              aria-hidden="true"
            ></span>
          </span>
          <span className="ml-3.5 font-medium text-gray-700">
            waiting for reply
          </span>
        </a>
      </p>
    );
  }

  let blockElement;

  switch (item.element) {
    case 'button':
      blockElement = (
        <p>
          <strong>reply button:</strong> {item.label}
        </p>
      );

      break;
    default:
      if (blocks.type === 'app_package') {
        blockElement = (
          <div>
            <p>{blocks.appPackage}</p>

            <br />

            <div>
              {data && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.formattedText,
                  }}
                />
              )}
            </div>
          </div>
        );

        break;
      }

      if (blocks.type === 'data_retrieval') {
        const dataList = Object.keys(message.message.data).map((k) => {
          return (
            <p key={`data-message-${message.id}-${k}`}>
              {k}: {message.message.data[k]}
            </p>
          );
        });

        blockElement = (
          <React.Fragment>
            <strong>replied:</strong>
            {dataList}
          </React.Fragment>
        );
        break;
      } else {
        blockElement = <p>{JSON.stringify(message.message.data)}</p>;
        break;
      }
  }

  return (
    <div
      id={`message-id-${message.id}`}
      className={'flex items-start py-2 text-sm'}
      key={`conversations-messages/${message.id}`}
    >
      <div
        className={`bg-green-400
        flex 
        overflow-hidden p-2 
        rounded-md mx-auto text-white`}
      >
        <div className="flex flex-col justify-between">
          <span className={'text-xs text-center'}>
            <Moment fromNow ago>
              {message.createdAt}
            </Moment>

            <span>
              {' - '}
              {message.readAt ? (
                <span>{I18n.t('conversation.messages.seen')}</span>
              ) : (
                <span>{I18n.t('conversation.messages.not_seen')}</span>
              )}
            </span>
          </span>

          <div className="text-md text-center text-bold">{blockElement}</div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { auth, app, conversation, app_user, current_user, drawer } = state;
  const { isAuthenticated } = auth;
  const { messages, loading } = conversation;
  const { jwt } = auth;

  return {
    jwt,
    conversation,
    current_user,
    messages,
    loading,
    app_user,
    app,
    drawer,
    isAuthenticated,
  };
}

export default withRouter(connect(mapStateToProps)(Conversation));
