const ReportTypes = {
  LEAD: {
    index: 0,
    title: 'chat volume',
  },
  VISITOR: {
    index: 1,
    title: 'unattended chats',
  },
  MISSED: {
    index: 2,
    title: 'missed chats',
  },
  TOTAL: {
    index: 3,
    title: 'total traffic',
  },
  TAG: {
    index: 4,
    title: 'tag chats',
  },
};

export default ReportTypes;
