import React, { useEffect, useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import Progress from '@chaskiq/components/src/components/Progress';
import EmptyView from '@chaskiq/components/src/components/EmptyView';

import ConversationSearch from './offline-conversations/Search';
import ConversationItemList from './offline-conversations/ItemList';
import Conversation from './offline-conversations/Conversation';
import { useHistory } from 'react-router-dom';

import I18n from '../shared/FakeI18n';
import '../styles/styles.css';
import {
  getOfflineConversations,
  clearOfflineConversations,
} from '@chaskiq/store/src/actions/offlineConversations';
import styled from '@emotion/styled';

export const CounterCnversationsCircle = styled.div`
  padding: 5px;
  border-radius: 100%;
  border: 3px solid #3c93c9;
  background: white;
  margin: 5px;
  text-align: center;
  min-width: 40px;
  min-height: 40px;
`;

function OfflineConversations({ app, dispatch, conversations, conversation }) {
  const [fetching, setFetching] = useState(false);
  const [currentAppKey, setCurrentAppKey] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    dateValues: [null, null],
    searchKey: null,
    agentEmail: null,
    tagName: null,
    selectedApp: null,
    selectedAppName: '',
    exactSearchKey: true,
  });

  useEffect(() => {
    dispatch(clearOfflineConversations([]));
    setFetching(true);
    fetchConversations({ page: 1 }, () => {
      setFetching(false);
    });
  }, []);

  const fetchConversations = (options, cb = null) => {
    dispatch(
      getOfflineConversations(
        options,
        () => {
          cb && cb();
        },
        true
      )
    );
  };

  const handleScroll = (e) => {
    const element = e.target;
    const scrollDiff = Math.round(element.scrollHeight - element.scrollTop);
    if ([0, 1, 2, 3].includes(Math.abs(scrollDiff - element.clientHeight))) {
      if (conversations.meta.next_page && !fetching) {
        setFetching(true);
        const start = filterOptions.dateValues[0];
        const end = filterOptions.dateValues[1];
        fetchConversations(
          {
            isExact: filterOptions.exactSearchKey ? 'equal' : 'like',
            from: start
              ? `${start.getFullYear()}-${
                  start.getMonth() + 1
                }-${start.getDate()}`
              : null,
            to: end
              ? `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`
              : null,
            email: filterOptions.agentEmail,
            tag: filterOptions.tagName,
            selectedApp: filterOptions.selectedApp,
            page: conversations.meta.next_page,
            term: filterOptions.searchKey,
          },
          () => {
            setFetching(false);
          }
        );
      }
    }
  };

  const renderConversations = () => {
    return (
      <>
        <div className="items-center bg-white dark:bg-gray-800 px-3 py-4 border-b border-gray-200 dark:border-gray-700 sm:px-3 flex justify-between">
          <ConversationSearch
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
        </div>

        <div
          id="offline-conv-list"
          className="overflow-scroll"
          onScroll={handleScroll}
          style={{ height: 'calc(100vh - 60px)' }}
        >
          <div className="spanCounterConvo">
            <CounterCnversationsCircle>
              {!conversations?.loading &&
              conversations?.meta?.total_count != null
                ? conversations?.meta?.total_count
                : '🔃'}
            </CounterCnversationsCircle>
          </div>

          {conversations.collection.map((o) => {
            return (
              <ConversationItemList
                setCurrentAppKey={setCurrentAppKey}
                key={o.key}
                app={app}
                conversation={o}
              />
            );
          })}

          {conversations.meta.total_pages === 0 && (
            <EmptyView
              title={I18n.t('conversations.empty.title')}
              shadowless
              h2Classes={`text-2xl tracking-tight
              font-extrabold text-gray-900 sm:text-3xl
              sm:leading-none md:text-2xl`}
            />
          )}

          {(fetching || conversations.loading) && (
            <div className="m-2">
              <Progress size={conversations.collection.length === 0 ? 16 : 4} />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="flex">
      <Switch>
        <Route exact path={`/apps/${app.key}/reports/conversations`}>
          <div className={'w-full md:w-1/4 h-screen md:border-r sm:hidden'}>
            {renderConversations()}
          </div>
        </Route>
      </Switch>

      <div
        className={
          'w-full md:w-4/12 h-screen md:border-r hidden sm:block border-gray-200 dark:border-gray-800'
        }
      >
        {renderConversations()}
      </div>

      <Switch>
        <Route exact path={`/apps/${app.key}/reports/conversations`}>
          <div className="hidden sm:block flex-grow bg-gray-50 dark:bg-gray-800 h-screen border-r-0 w-1/12">
            <EmptyView
              title={I18n.t('conversations.empty.title')}
              shadowless
              image={
                <img
                  loading="lazy"
                  src="/assets/images/empty-icon8.png"
                  className="h-56 w-56"
                  alt={I18n.t('conversations.empty.title')}
                />
              }
              subtitle={I18n.t('conversations.empty.text')}
            />
          </div>
        </Route>

        <Route exact path={`/apps/${app.key}/reports/conversations/:id`}>
          {currentAppKey ? (
            <div className="md:w-0 md:flex-grow w-full bg-gray-200 dark:bg-gray-900 h-screen border-r dark:border-black">
              <Conversation currentAppKey={currentAppKey} />
            </div>
          ) : (
            <BackConversations appKey={app.key} />
          )}
        </Route>
      </Switch>
    </div>
  );
}

function BackConversations({ appKey }) {
  const history = useHistory();
  useEffect(() => {
    history.push(`/apps/${appKey}/reports/conversations`);
  }, []);
  return <></>;
}

function mapStateToProps(state) {
  const { app, offlineConversations, conversation } = state;

  return {
    app,
    conversations: offlineConversations,
    conversation,
  };
}

export default withRouter(connect(mapStateToProps)(OfflineConversations));
