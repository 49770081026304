import { assignAgent } from '@chaskiq/store/src/actions/conversation';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function SimpleTabs({ tabs, buttons, conversation }) {
  const [value, setValue] = React.useState(0);
  const [joining, setJoining] = React.useState<boolean>(false);

  const user = useSelector((state: any) => state.current_user);

  const dispatch = useDispatch();

  return (
    <div className="w-full">
      <div className="border-b border-gray-200">
        <div className="-mb-px flex editor-nav">
          {tabs.map((o, i) => (
            <a
              href="#"
              onClick={() => {
                setValue(i);
              }}
              key={'conversation-tab' + o.label}
              className="whitespace-nowrap py-2 px-2 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              {o.label}
            </a>
          ))}

          {(conversation.assignee?.isBot || !conversation.assignee) && (
            <a
              style={{
                color: '#cfefcf',
                backgroundColor: joining ? 'grey' : 'green',
                border: 'double',
              }}
              href="#"
              onClick={() => {
                if (!joining) {
                  setJoining(true);
                  dispatch(
                    assignAgent(user.id, conversation, () => setJoining(false))
                  );
                }
              }}
              className="whitespace-nowrap py-2 px-2 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
            >
              Join
            </a>
          )}

          {buttons && buttons()}
        </div>
      </div>

      {<div>{tabs[value].content}</div>}
    </div>
  );
}
