import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import { AiOutlineCalendar } from 'react-icons/ai';

export default function _DateRangePicker({ value, setValue, disabled }) {
  const StartComponent = (
    <div className="start-date">
      <div className="icon">
        <AiOutlineCalendar size={15} />
      </div>
      <small style={{ fontSize: '70%' }}>Start</small>
    </div>
  );
  const EndComponent = (
    <div className="end-date">
      <div className="icon">
        <AiOutlineCalendar size={15} />
      </div>
      <small style={{ fontSize: '70%' }}>End</small>
    </div>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <DateRangePicker
          calendars={1}
          value={value}
          disabled={disabled ? true : false}
          startText={StartComponent}
          endText={EndComponent}
          // @ts-ignore
          // style={{ height: 'auto', padding: '0' }}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField size="small" autoComplete="off" {...startProps} />
              <Box sx={{ mx: 1 }}> : </Box>
              <TextField size="small" autoComplete="off" {...endProps} />
            </React.Fragment>
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
