import { ActionType } from '../constants/action_types';
import { CONVERSATIONS } from '../graphql/queries';
import graphql from '../graphql/client';

// TYPES
const Types = {
  GET_OFFLINE_CONVERSATIONS: 'GET_OFFLINE_CONVERSATIONS',
  CLEAR_OFFLINE_CONVERSATIONS: 'CLEAR_OFFLINE_CONVERSATIONS',
  UPDATE_OFFLINE_CONVERSATIONS: 'UPDATE_OFFLINE_CONVERSATIONS',
  SelectSearchApp: 'SELECT_SEARCH_APP',
};

// ACTIONS

const dispatchDataUpate = (data) => {
  return {
    type: Types.UPDATE_OFFLINE_CONVERSATIONS,
    data: data,
  };
};

const dispatchGetConversations = (data) => {
  return {
    type: Types.GET_OFFLINE_CONVERSATIONS,
    data: data,
  };
};
// FUNCTIONS
export const updateOfflineConversationsData = (data, cb) => {
  return (dispatch, _getState) => {
    dispatch(dispatchDataUpate(data));
    cb && cb();
  };
};

export function selectSearchApp(app) {
  return {
    type: Types.SelectSearchApp,
    data: app,
  };
}

export function selectDateRange(app) {
  return {
    type: Types.SelectSearchApp,
    data: app,
  };
}

export const clearOfflineConversations = (data) => {
  return (dispatch, _getState) => {
    dispatch({
      type: Types.CLEAR_OFFLINE_CONVERSATIONS,
      data: data,
    });
  };
};

export function getOfflineConversations(options, cb,isOffline=false) {
  const { page } = options;
  return (dispatch, getState) => {
    const {
      sort,
      filter,
      agentId,
      meta,
      tag,
      term,
      exact,
      selectedApp,
    } = getState().offlineConversations;

    const nextPage = page || meta.next_page || 1;

    dispatch(dispatchDataUpate({ loading: true }));
    graphql(
      CONVERSATIONS,
      {
        appKey: getState().app.key,
        page: nextPage,
        sort: sort,
        filter: filter,
        agentId: agentId,
        tag: tag,
        term: term,
        email: options.email,
        from: options.from,
        to: options.to,
        selected_app: options.selectedApp ? options.selectedApp : selectedApp,
        type: options.isExact ? options.isExact : exact ? 'equal' : 'like',
        all_apps: true,
        is_offline: isOffline,
      },
      {
        success: (data) => {
          const conversations = data.app.conversations;
          const newData = {
            collection:
              nextPage > 1
                ? getState().offlineConversations.collection.concat(
                    conversations.collection
                  )
                : conversations.collection,
            meta: conversations.meta,
            loading: false,
          };
          dispatch(dispatchGetConversations(newData));

          if (cb) cb();
        },
        error: (e) => {
          console.log('error', e);
        },
      }
    );
  };
}

// Reducer
export default function reducer(
  state = {
    meta: {},
    sort: 'newest',
    filter: 'opened',
    loading: false,
    collection: [],
    agentId: null,
    tag: null,
    term: null,
    selectedApp: null,
  },
  action: ActionType = {}
) {
  switch (action.type) {
    case Types.GET_OFFLINE_CONVERSATIONS: {
      return Object.assign({}, state, action.data);
    }

    case Types.CLEAR_OFFLINE_CONVERSATIONS: {
      return Object.assign({}, state, { collection: [] });
    }

    case Types.UPDATE_OFFLINE_CONVERSATIONS: {
      return Object.assign({}, state, action.data);
    }

    case Types.SelectSearchApp: {
      return { ...state, selected_app: action.data };
    }

    default:
      return state;
  }
}
