import React from 'react';
import { Link } from 'react-router-dom';
import { readableColor } from 'polished';
import sanitizeHtml, {
  escapeHTML,
} from '@chaskiq/components/src/utils/htmlSanitize';
import { LabelIcon } from '@chaskiq/components/src/components/icons';
import { MdSupportAgent } from 'react-icons/md';
import { RiUserFill } from 'react-icons/ri';
import { FaRobot } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';

export function textColor(color) {
  const lightReturnColor = '#121212';
  const darkReturnColor = '#f3f3f3';
  return readableColor(color, lightReturnColor, darkReturnColor);
}

export default function ConversationItemList({
  app,
  conversation,
  setCurrentAppKey,
}) {
  const renderConversationContent = (o) => {
    const message = o.lastMessage.message;
    if (message.htmlContent) {
      return sanitizeHtml(escapeHTML(message.htmlContent)).substring(0, 250);
    }
  };

  const user = conversation.mainParticipant;
  const message = conversation.lastMessage;
  const participant = conversation.mainParticipant;
  const appUser = message.appUser;
  const tags = conversation.tagList;

  function tagColor(tag) {
    const defaultColor = {
      bgColor: '#fed7d7',
      color: textColor('#fed7d7'),
    };

    if (!app.tagList) return defaultColor;

    const findedTag = app.tagList.find((o) => o.name === tag);

    if (!findedTag) return defaultColor;

    let newColor = findedTag.color || '#444';
    newColor = `#${newColor.replace('#', '')}`;

    return {
      bgColor: newColor,
      color: textColor(newColor),
    };
  }

  function renderTag(tag) {
    const color = tagColor(tag);
    return (
      <span
        key={`conversation-${conversation.key}-tag-${tag}`}
        style={{
          backgroundColor: color.bgColor,
          color: color.color,
        }}
        className="mr-0.5 inline-flex items-center px-2.5 py-0.5
        rounded-full text-xs font-light
        bg-gray-100 text-blue-800
        capitalize"
      >
        #{tag}
      </span>
    );
  }

  function stateClass() {
    return conversation.state == 'opened'
      ? 'bg-red-200 dark:bg-red-600'
      : 'bg-green-600';
  }

  return (
    <div
      onClick={() => {
        setCurrentAppKey(conversation.appKey);
      }}
    >
      <Link
        to={`/apps/${app.key}/reports/conversations/${conversation.key}`}
        className="flex justify-between hover:bg-gray-50 dark:hover:bg-gray-900 focus:outline-none focus:bg-gray-50 dark:focus:bg-gray-800 transition duration-150 ease-in-out"
        data-cy={`conversation-item`}
      >
        <div className={`block w-2 ${stateClass()}`}></div>
        <div
          style={
            !conversation?.lastMessage?.readAt &&
            conversation?.lastMessage?.appUser?.kind !== 'agent'
              ? {
                  border: '1px solid red',
                  backgroundColor: '#fff0f0',
                }
              : {}
          }
          className="w-full px-4 py-4 whitespace-nowrap border-b border-gray-200 dark:border-gray-800 dark:hover:bg-gray-800"
        >
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              {conversation?.whatsappId != null ? (
                <div className="w-10 h-10 rounded-full flex justify-center items-center">
                  <IoLogoWhatsapp
                    color="#33D952"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              ) : (
                <div className="w-10 h-10 rounded-full flex justify-center items-center bg-gray-600">
                  <RiUserFill color="white" />
                </div>
              )}
            </div>

            <div className="ml-4 truncate w-full">
              <div className="flex justify-between">
                <span className="text-md leading-5 font-semibold text-gray-800 dark:text-gray-100 truncate mr-2">
                  {user.displayName}
                </span>

                <span className="text-xs leading-5" style={{ color: '#999' }}>
                  {new Date(message.createdAt).toLocaleDateString('en-UK') +
                    ' ' +
                    new Date(message.createdAt).toLocaleTimeString()}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-5 space-y-2">
            <span className="text-xs leading-5" style={{ color: '#999' }}>
              {'Created at: ' +
                new Date(conversation.createdAt).toLocaleDateString('en-UK') +
                ' ' +
                new Date(conversation.createdAt).toLocaleTimeString()}
            </span>
            <div className="space-x-2 text-sm leading-5 text-gray-500 dark:text-gray-100 flex pb-2 pt-1">
              {appUser && appUser.id !== participant.id && (
                <div className="w-6 h-6 rounded-full flex justify-center items-center bg-gray-300">
                  {appUser?.kind == 'bot' || appUser?.isBot ? (
                    <FaRobot color="black" />
                  ) : (
                    <MdSupportAgent color="black" />
                  )}
                </div>
              )}

              {message.privateNote && (
                <span>
                  <LabelIcon />
                </span>
              )}

              <span
                className="truncate"
                dangerouslySetInnerHTML={{
                  __html: renderConversationContent(conversation),
                }}
              />
            </div>

            <div className="flex justify-between">
              <div className="flex">
                {conversation.conversationChannels &&
                  conversation.conversationChannels.map((o) => (
                    <div
                      key={`${conversation.key}-${o}`}
                      className="mr-0.5 border inline-flex items-center px-2.5 py-0.5 
                    rounded-full text-xs font-medium bg-gray-100 
                    text-blue-800 capitalize dark:bg-gray-900 dark:text-gray-100"
                    >
                      {o}
                    </div>
                  ))}
              </div>

              {tags && tags.length > 0 && (
                <div className="flex overflow-auto ml-4 mr-1">
                  {tags.map((o) => renderTag(o))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
