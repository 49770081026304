import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import { MdOutlineClear } from 'react-icons/md';
import Tooltip from '@mui/material/Tooltip';

export default function _DateRangePicker({ value, setValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <Typography sx={{ mt: 2, mb: 1 }}>Date interval </Typography>
        <DateRangePicker
          calendars={1}
          value={value.dateValues}
          onChange={(newValue) => {
            setValue({ ...value, dateValues: newValue });
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField autoComplete="off" {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField autoComplete="off" {...endProps} />
              <div style={{ display: 'flex', padding: '1em' }}>
                <Tooltip title="clear" placement="top">
                  <span>
                    <MdOutlineClear
                      onClick={() =>
                        setValue({ ...value, dateValues: [null, null] })
                      }
                    />
                  </span>
                </Tooltip>
              </div>
            </React.Fragment>
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
