import React, { useState } from 'react';
import Moment from 'react-moment';
import { readableColor } from 'polished';
import sanitizeHtml, {
  escapeHTML,
} from '@chaskiq/components/src/utils/htmlSanitize';
import { LabelIcon } from '@chaskiq/components/src/components/icons';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { MdSupportAgent } from 'react-icons/md';
import { RiUserFill } from 'react-icons/ri';
import { FaRobot } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';

export function textColor(color) {
  const lightReturnColor = '#121212';
  const darkReturnColor = '#f3f3f3';
  return readableColor(color, lightReturnColor, darkReturnColor);
}

export default function ConversationItemList({
  app,
  conversation,
  openNewConversation,
}) {
  // const renderConversationContent = (o) => {
  //   console.log('conv', o);
  //   const message = o.lastMessage.message;
  //   console.log('message', message);
  //   if (message.htmlContent) {
  //     // return sanitizeHtml(escapeHTML(message.htmlContent)).substring(0, 250);
  //     console.log('escaped', sanitizeHtml(escapeHTML(message.htmlContent)));
  //     return sanitizeHtml(escapeHTML(message.htmlContent));
  //   }
  // };

  const [clickTrigger, setClickTrigger] = useState(false);

  const cUser = conversation.mainParticipant;
  const user = {
    ...cUser,
    shortDisplayName:
      cUser.displayName.slice(0, 1) + '-' + cUser.displayName.slice(7),
  };
  const message = conversation.lastMessage;
  const participant = conversation.mainParticipant;
  const appUser = message.appUser;
  const tags = conversation.tagList;

  function tagColor(tag) {
    const defaultColor = {
      bgColor: '#fed7d7',
      color: textColor('#fed7d7'),
    };

    if (!app.tagList) return defaultColor;

    const findedTag = app.tagList.find((o) => o.name === tag);

    if (!findedTag) return defaultColor;

    let newColor = findedTag.color || '#444';
    newColor = `#${newColor.replace('#', '')}`;

    return {
      bgColor: newColor,
      color: textColor(newColor),
    };
  }

  function renderTag(tag) {
    const color = tagColor(tag);
    return (
      <span
        key={`conversation-${conversation.key}-tag-${tag}`}
        style={{
          backgroundColor: color.bgColor,
          color: color.color,
        }}
        className="mr-0.5 inline-flex items-center px-2.5 py-0.5
        rounded-full text-xs font-light
        bg-gray-100 text-blue-800
        capitalize"
      >
        #{tag}
      </span>
    );
  }

  // function stateClass() {
  //   return conversation.state == 'opened'
  //     ? 'bg-red-200 dark:bg-red-600'
  //     : 'bg-green-600';
  // }

  const handleConversationClick = () => {
    if (!clickTrigger) {
      setClickTrigger(true);
      openNewConversation(conversation, () => setClickTrigger(false));
    }
  };

  const selector = useSelector((state: any) => state.openConversations);
  const isOpenedActive = () => {
    for (var i = 0, len = selector.data.length; i < len; i++) {
      if (selector.data[i].key === conversation.key) {
        return true;
      }
    }
    return false;
  };

  return (
    <div
      onClick={handleConversationClick}
      className="flex justify-between focus:outline-none focus:bg-gray-50 dark:focus:bg-gray-800 transition duration-150 ease-in-out item-list-container"
      data-cy={`conversation-item`}
    >
      <div className={`block w-2`}></div>
      <div
        style={
          conversation?.lastMessage?.appUser?.kind !== 'agent' &&
          !conversation?.lastMessage?.readAt &&
          !isOpenedActive()
            ? {
                border: '1px solid red',
                backgroundColor: '#fff0f0',
              }
            : {}
        }
        className="w-full px-4 py-4 whitespace-nowrap border-b border-gray-200 dark:border-gray-800 dark:hover:bg-gray-800"
      >
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            {conversation?.whatsappId != null ? (
              <div className="w-10 h-10 rounded-full flex justify-center items-center">
                <IoLogoWhatsapp
                  color="#33D952"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            ) : (
              <div className="w-10 h-10 rounded-full flex justify-center items-center bg-gray-600">
                <RiUserFill color="white" />
              </div>
            )}
          </div>

          <div className="ml-4 truncate w-full">
            <div className="flex justify-between">
              <Tooltip title={user.displayName}>
                <span className="text-md leading-5 font-semibold text-gray-800 dark:text-gray-100 truncate mr-2">
                  {user.shortDisplayName}
                </span>
              </Tooltip>
              <span className="text-xs leading-5" style={{ color: '#999' }}>
                <Moment fromNow ago>
                  {message.createdAt}
                </Moment>
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-5 space-y-2">
          <div className="space-x-2 text-sm leading-5 text-gray-500 dark:text-gray-100 flex pb-2 pt-1">
            {appUser && appUser.id !== participant.id && (
              <div className="w-6 h-6 rounded-full flex justify-center items-center bg-gray-300">
                {appUser?.kind == 'bot' || appUser?.isBot ? (
                  <FaRobot color="black" />
                ) : (
                  <MdSupportAgent color="black" />
                )}
              </div>
            )}

            {message.privateNote && (
              <span>
                <LabelIcon />
              </span>
            )}

            <span
              className="truncate"
              // dangerouslySetInnerHTML={{
              //   __html: renderConversationContent(conversation),
              // }}
            />
            {conversation.lastMessage?.message?.textContent?.substring(0, 30)}
          </div>

          <div className="flex justify-between">
            <div className="flex">
              {conversation.conversationChannels &&
                conversation.conversationChannels.map((o) => (
                  <div
                    key={`${conversation.key}-${o}`}
                    className="mr-0.5 border inline-flex items-center px-2.5 py-0.5 
                    rounded-full text-xs font-medium bg-gray-100 
                    text-blue-800 capitalize dark:bg-gray-900 dark:text-gray-100"
                  >
                    {o}
                  </div>
                ))}
            </div>

            {tags && tags.length > 0 && (
              <div className="flex overflow-auto ml-4 mr-1">
                {tags.map((o) => renderTag(o))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
