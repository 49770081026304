import React, { useState, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import Progress from '@chaskiq/components/src/components/Progress';
import EmptyView from '@chaskiq/components/src/components/EmptyView';
import { BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';
import ConversationItemList from './conversations/ItemList';
import AssignmentRules from './conversations/AssignmentRules';
import Conversation from './conversations/Conversation';
import AccessDenied from '@chaskiq/components/src/components/AccessDenied';

import I18n from '../shared/FakeI18n';
import '../styles/styles.css';
import './conversations/styles.scss';
import {
  getConversations,
  clearConversations,
} from '@chaskiq/store/src/actions/conversations';

import {
  openConversation,
  closeConversation,
  get_next_tab,
  updateActiveIndex,
  update_start_index,
} from '@chaskiq/store/src/actions/openConversations';
import {
  setCurrentSection,
  setCurrentPage,
} from '@chaskiq/store/src/actions/navigation';
import styled from '@emotion/styled';
import graphql from '@chaskiq/store/src/graphql/client';
import { AGENTS } from '@chaskiq/store/src/graphql/queries';

export const CounterCnversationsCircle = styled.div`
  padding: 5px;
  border-radius: 100%;
  border: 3px solid;
  background: white;
  margin: 5px;
  text-align: center;
  min-width: 40px;
  min-height: 40px;
`;

function Conversations({
  dispatch,
  conversations,
  app,
  events,
  openConversations,
}) {
  const [fetching, setFetching] = useState(false);
  const [unseenBack, setUnseenBack] = useState(false);
  const [unseenNext, setUnseenNext] = useState(false);
  const [agents, setAgents] = useState([]);

  const changeActiveElement = () => {
    const classes = document.activeElement.classList;
    if (
      !classes.contains('public-DraftEditor-content') &&
      !classes.contains('still-fouced')
    ) {
      dispatch(updateActiveIndex(-1));
    }
  };

  useEffect(() => {
    window.addEventListener('focus', changeActiveElement);

    return () => {
      window.removeEventListener('focus', changeActiveElement);
    };
  }, []);

  useEffect(() => {
    setUnseenBack(false);
    setUnseenNext(false);

    for (let i = 0; i < openConversations.startIndex; i++) {
      if (!openConversations.data[i].seen) {
        setUnseenBack(true);
        break;
      }
    }

    for (
      let i = openConversations.startIndex + openConversations.perPage,
        len = openConversations.data.length;
      i < len;
      i++
    ) {
      if (!openConversations.data[i].seen) {
        setUnseenNext(true);
        break;
      }
    }
  }, [openConversations.data]);

  useEffect(() => {
    graphql(
      AGENTS,
      {
        appKey: app.key,
      },
      {
        success: (data) => {
          setAgents(data.app.agents);
        },
        error: (e) => {
          console.log('error', e);
        },
      }
    );
  }, []);

  const tabHandler = (e, nextTab) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const inputFields = document.querySelectorAll(
        '.public-DraftEditor-content'
      );
      if (inputFields.length && openConversations.activeIndex > -1) {
        dispatch(get_next_tab(nextTab));
      }
    }
  };

  const isConversationOpen = (conversationKey) => {
    let exist = false;
    // check if exist
    for (let i = 0, len = openConversations?.data.length; i < len; i++) {
      if (openConversations.data[i].key === conversationKey) {
        exist = true;
        break;
      }
    }
    return exist;
  };

  const openNewConversation = (conversation, cb) => {
    if (!isConversationOpen(conversation.key)) {
      dispatch(openConversation(conversation, cb));
    } else {
      cb && cb();
    }
  };

  const colseConversation = (identifier) => {
    dispatch(closeConversation(identifier));
  };

  useEffect(() => {
    dispatch(clearConversations([]));
    setFetching(true);
    fetchConversations({ page: 1 }, () => {
      setFetching(false);
    });

    dispatch(setCurrentPage('Conversations'));
    dispatch(setCurrentSection('Conversations'));
  }, []);

  const fetchConversations = (options, cb = null) => {
    dispatch(
      getConversations(options, () => {
        cb && cb();
      })
    );
  };

  const handleScroll = (e) => {
    const element = e.target;
    const scrollDiff = Math.round(element.scrollHeight - element.scrollTop);
    // used to make the scroll margin within diff upto 3
    if ([0, 1, 2, 3].includes(Math.abs(scrollDiff - element.clientHeight))) {
      if (conversations.meta.next_page && !fetching) {
        setFetching(true);
        fetchConversations(
          {
            page: conversations.meta.next_page,
          },
          () => {
            setFetching(false);
          }
        );
      }
    }
  };

  const incrementStartIndex = () => {
    if (
      openConversations.startIndex <
      openConversations.data.length - openConversations.perPage
    )
      dispatch(update_start_index(openConversations.startIndex + 1));
  };
  const decrementStartIndex = () => {
    if (openConversations.startIndex > 0) {
      dispatch(update_start_index(openConversations.startIndex - 1));
    }
  };

  const BackButton = () => (
    <div className="slider-button-container left">
      <button
        disabled={openConversations.startIndex === 0}
        className={`slider-button ${unseenBack ? 'unseen' : ''}`}
        onClick={decrementStartIndex}
      >
        <span>
          <BiChevronsLeft />
        </span>
      </button>
    </div>
  );

  const NextButton = () => (
    <div className="slider-button-container right">
      <button
        disabled={
          openConversations.startIndex ===
          openConversations.data.length - openConversations.perPage
        }
        className={`slider-button ${unseenNext ? 'unseen' : ''}`}
        onClick={incrementStartIndex}
      >
        <span>
          <BiChevronsRight />
        </span>
      </button>
    </div>
  );

  const renderConversations = () => {
    return (
      <React.Fragment>
        <div
          onScroll={handleScroll}
          style={{ height: '100%', overflowX: 'hidden', overflowY: 'scroll' }}
        >
          <div className="spanCounterConvo">
            <CounterCnversationsCircle style={{ borderColor: '#3c93c9' }}>
              {!conversations?.loading ? conversations?.total_count : '🔃'}
            </CounterCnversationsCircle>
            <CounterCnversationsCircle style={{ borderColor: 'red' }}>
              {!conversations?.loading_unread &&
              conversations?.conversations_unread_count != null
                ? conversations?.conversations_unread_count
                : '🔃'}
            </CounterCnversationsCircle>
          </div>

          {conversations.collection.map((o) => {
            const user = o.mainParticipant;
            return (
              <ConversationItemList
                key={o.key}
                app={app}
                conversation={o}
                openNewConversation={openNewConversation}
              />
            );
          })}

          {conversations.collection.length === 0 && !conversations.loading && (
            <EmptyView
              title={I18n.t('conversations.empty.title')}
              shadowless
              h2Classes={`text-2xl tracking-tight
              font-extrabold text-gray-900 sm:text-3xl
              sm:leading-none md:text-2xl`}
            />
          )}

          {(fetching || conversations.loading) && (
            <div className="m-2">
              <Progress size={conversations.collection.length === 0 ? 16 : 4} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  const renderOpendConversations = (conv, idx) => {
    return (
      <div
        onKeyDown={(e) => {
          tabHandler(
            e,
            (idx + openConversations.startIndex + 1) %
              openConversations.data.length
          );
        }}
        onClick={() => {
          dispatch(updateActiveIndex(idx + openConversations.startIndex));
        }}
        key={idx}
        className="md:w-0 md:flex-grow w-full bg-gray-200 dark:bg-gray-900 h-screen border-r dark:border-black"
      >
        <Conversation
          conversation={conv}
          events={events}
          colseConversation={colseConversation}
          identifier={openConversations.startIndex + idx}
          agents={agents}
        />
      </div>
    );
  };

  return (
    <div className="flex">
      <Switch>
        <Route exact path={`/apps/${app.key}/conversations`}>
          <div
            className={
              'w-full h-screen md:border-r sm:hidden conversations-list'
            }
          >
            {renderConversations()}
          </div>
        </Route>
      </Switch>

      <div
        className={
          'w-full h-screen md:border-r hidden sm:block border-gray-200 dark:border-gray-800 conversations-list'
        }
      >
        {renderConversations()}
      </div>

      <Switch>
        <Route exact path={`/apps/${app.key}/conversations/assignment_rules`}>
          <div className="flex-grow bg-gray-50 dark:bg-gray-800 h-screen border-r w-1/12 dark:border-black">
            <AccessDenied section="assign_rules">
              <AssignmentRules />
            </AccessDenied>
          </div>
        </Route>

        <Route exact path={`/apps/${app.key}/conversations`}>
          {openConversations?.data.length ? (
            <div style={{ display: 'flex', width: '100%' }}>
              <BackButton />
              {openConversations.data.length <= openConversations.perPage &&
                openConversations.data.map((c, idx) =>
                  renderOpendConversations(c, idx)
                )}

              {openConversations.data.length > openConversations.perPage &&
                openConversations.data
                  .slice(
                    openConversations.startIndex,
                    openConversations.startIndex + openConversations.perPage
                  )
                  .map((c, idx) => renderOpendConversations(c, idx))}
              <NextButton />
            </div>
          ) : (
            <div className="hidden sm:block flex-grow bg-gray-50 dark:bg-gray-800 h-screen border-r-0 w-1/12">
              <EmptyView
                title={I18n.t('conversations.empty.title')}
                shadowless
                image={
                  <img
                    loading="lazy"
                    src="/assets/images/empty-icon8.png"
                    className="h-56 w-56"
                    alt={I18n.t('conversations.empty.title')}
                  />
                }
                subtitle={I18n.t('conversations.empty.text')}
              />
            </div>
          )}
        </Route>
      </Switch>
    </div>
  );
}

function mapStateToProps(state) {
  const {
    auth,
    app,
    conversations,
    conversation,
    app_user,
    openConversations,
  } = state;
  const { isAuthenticated } = auth;

  return {
    conversations,
    conversation,
    app_user,
    app,
    isAuthenticated,
    openConversations,
  };
}

export default withRouter(connect(mapStateToProps)(Conversations));
