import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import '../styles/toast.css';
import { useSelector } from 'react-redux';

export default function DesktopNotification() {
  const selector = useSelector((state: any) => state.navigation);
  return (
    selector.current_page !== 'Conversations' &&
    selector.current_section !== 'Conversations' && (
      <ToastContainer
        autoClose={6000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // @ts-ignore
        pauseOnFocusLoss={false}
        draggable
        closeButton={false}
        pauseOnHover={false}
        position="bottom-right"
      />
    )
  );
}
