import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const _Spinner = () => {
  return (
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
  );
};

export default _Spinner;

const SpinnerAnim = keyframes`
to {transform: rotate(360deg);}
`;

const Spinner = styled.div`
  animation: ${SpinnerAnim} 0.6s linear infinite;
  width: 60px;
  height: 60px;
  border: 4px solid #ccc;
  border-top: 4px solid white;
  border-radius: 50%;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20%;
`;
