import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FormDialog from '@chaskiq/components/src/components/FormDialog';
import { SeachIcon } from '@chaskiq/components/src/components/icons';
import Button from '@chaskiq/components/src/components/Button';
import I18n from '../../shared/FakeI18n';
import { AiOutlineMail, AiFillTags } from 'react-icons/ai';
import DateRangePicker from './DateRangePicker';
import graphql from '@chaskiq/store/src/graphql/client';
import { APPS } from '@chaskiq/store/src/graphql/queries';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
  getOfflineConversations,
  clearOfflineConversations,
  updateOfflineConversationsData,
  selectSearchApp,
} from '@chaskiq/store/src/actions/offlineConversations';

function ConversationSearch({
  _app,
  dispatch,
  conversations,
  asButton,
  setFilterOptions,
  filterOptions,
}) {
  const [open, setOpen] = useState(false);
  const [currentApps, setApps] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    graphql(
      APPS,
      {},
      {
        success: (data) => {
          setApps(data.apps);
        },
        error: () => {},
      }
    );
  }, []);

  function fetchConversations(options, cb = null) {
    const start = filterOptions.dateValues[0];
    const end = filterOptions.dateValues[1];
    const searchOptions = {
      isExact: filterOptions.exactSearchKey ? 'equal' : 'like',
      from: start
        ? `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`
        : null,
      to: end
        ? `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`
        : null,
      email: filterOptions.agentEmail,
      tag: filterOptions.tagName,
      selectedApp: filterOptions.selectedApp,
    };

    const newOptions = Object.assign({}, options, searchOptions);

    dispatch(
      getOfflineConversations(
        newOptions,
        () => {
          cb && cb();
        },
        true
      )
    );
  }

  function handleSubmit() {
    dispatch(selectSearchApp(filterOptions.selectedApp));
    dispatch(clearOfflineConversations([]));
    dispatch(
      updateOfflineConversationsData(
        {
          term: filterOptions.searchKey,
          agentEmail: filterOptions.agentEmail,
          dateValues: filterOptions.dateValues,
          exact: filterOptions.exactSearchKey,
          tag: filterOptions.tagName,
          selectedApp: filterOptions.selectedApp,
        },
        () => {
          setOpen(false);
          fetchConversations({ page: 1 });
        }
      )
    );
  }

  function handleEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  return (
    <React.Fragment>
      {asButton && (
        <Button variant="icon" onClick={() => setOpen(true)}>
          <SeachIcon />
        </Button>
      )}

      {!asButton && (
        <>
          <div className="flex items-center justify-space w-full bg-gray-200 dark:bg-gray-900 rounded-md px-2 py-1- mx-2">
            <SeachIcon size="small" onClick={() => setOpen(true)} />
            <input
              className="w-full ml-2 bg-transparent active:outline-none focus:outline-none text-sm py-1"
              value={filterOptions.searchKey}
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  searchKey: e.target.value,
                })
              }
              onKeyDown={(e) => {
                handleEnter(e);
              }}
              placeholder={I18n.t('common.search_conversations')}
            />
          </div>
          <div className="form-check" style={{ width: '25%' }}>
            <input
              className="form-check-input"
              type="checkbox"
              id="exactSearchKey"
              checked={filterOptions.exactSearchKey}
              onChange={(e) =>
                setFilterOptions({
                  ...filterOptions,
                  exactSearchKey: !filterOptions.exactSearchKey,
                })
              }
            />
            <label
              className="form-check-label"
              htmlFor="exactSearchKey"
              style={{ fontSize: 'small' }}
            >
              Exact Key
            </label>
          </div>
        </>
      )}

      <FormDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={'eeie'}
        formComponent={
          <form onSubmit={handleSubmit}>
            <h3>{I18n.t('common.search_conversations')}</h3>

            <div className="flex items-center justify-space w-full border rounded-md px-2 py-2 mx-2 my-2">
              <SeachIcon size="small" onClick={() => setOpen(true)} />
              <input
                className="w-full ml-2 bg-transparent active:outline-none focus:outline-none text-sm py-1"
                value={filterOptions.searchKey}
                onChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    searchKey: e.target.value,
                  })
                }
                placeholder={I18n.t('common.search_conversations')}
              />
            </div>
            <div className="flex items-center justify-space w-full border rounded-md px-2 py-2 mx-2 my-2">
              <AiOutlineMail size={16} />
              <input
                className="w-full ml-2 bg-transparent active:outline-none focus:outline-none text-sm py-1"
                value={filterOptions.agentEmail}
                onChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    agentEmail: e.target.value,
                  })
                }
                placeholder={'Agent Email'}
              />
            </div>
            <div className="flex items-center justify-space w-full border rounded-md px-2 py-2 mx-2 my-2">
              <AiFillTags size={16} />
              <input
                className="w-full ml-2 bg-transparent active:outline-none focus:outline-none text-sm py-1"
                value={filterOptions.tagName}
                onChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    tagName: e.target.value,
                  })
                }
                placeholder={'Tag Name'}
              />
            </div>
            <div className="flex items-center justify-space w-full border rounded-md px-2 py-2 mx-2 my-2">
              <Autocomplete
                id="combo-box-apps"
                value={{
                  id: filterOptions?.selectedApp
                    ? filterOptions?.selectedApp
                    : null,
                  name: filterOptions?.selectedAppName
                    ? filterOptions?.selectedAppName
                    : '',
                }}
                onChange={(event, newValue) => {
                  setFilterOptions({
                    ...filterOptions,
                    selectedApp: newValue?.id ? newValue?.id : null,
                    selectedAppName: newValue?.name ? newValue?.name : '',
                  });
                }}
                getOptionLabel={(option) => option.name}
                options={currentApps}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select App"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-space w-full rounded-md px-2 py-1- mx-2 my-2">
              <DateRangePicker
                value={filterOptions}
                setValue={setFilterOptions}
              />
            </div>
            <button
              type="submit"
              style={{ float: 'right', borderRadius: '.8rem' }}
              className="-ml-px relative inline-flex items-center px-4 py-2 my-2 border
                border-gray-300 dark:border-white dark:bg-black dark:text-white 
                text-sm leading-5 font-medium rounded-r-md text-gray-700 bg-gray-50 
                hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue 
                focus:border-blue-300 active:bg-gray-100 active:text-gray-700 
                transition ease-in-out duration-150"
            >
              <span className="ml-2">{I18n.t('common.submit')}</span>
            </button>
          </form>
        }
      ></FormDialog>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  const { app, offlineConversations } = state;
  return {
    conversations: offlineConversations,
    app,
  };
}

export default withRouter(connect(mapStateToProps)(ConversationSearch));
