import React from 'react';

import Content from '@chaskiq/components/src/components/Content';
import EmptyView from '@chaskiq/components/src/components/EmptyView';

import { Link } from 'react-router-dom';
import I18n from '../shared/FakeI18n';

export default function NoFound() {
  return (
    <Content>
      <img loading="lazy" src="/assets/images/logo.png" />

      <EmptyView
        title="Not found"
        subtitle={
          <span>
            {I18n.t('common.not_found')}
            <Link to="/">{I18n.t('common.back_to_site')}</Link>
          </span>
        }
        image={
          <img
            loading="lazy"
            src="/assets/images/notfound-icon8.png"
            alt="not found"
          />
        }
      ></EmptyView>
    </Content>
  );
}
