import ActionTypes from '../constants/action_types';
import graphql from '../graphql/client';
import { getLastMessages } from './openConversations';
import { CONVERSATION } from '../graphql/queries';

import {
  INSERT_COMMMENT,
  INSERT_APP_BLOCK_COMMMENT,
  ASSIGN_USER,
  INSERT_NOTE,
  UPDATE_CONVERSATION_STATE,
  TOGGLE_CONVERSATION_PRIORITY,
  TYPING_NOTIFIER,
  UPDATE_CONVERSATION_TAG_LIST,
} from '../graphql/mutations';

import { camelCase } from 'lodash';
import { errorMessage } from './status_messages';
import { getConversations } from './conversations';

const pling = new Audio('/sounds/BLIB.wav');

export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export function getConversation(options, cb) {
  return (dispatch, getState) => {
    setLoading(true);

    const conversationMeta = getState().conversation.meta;
    const nextPage = conversationMeta ? conversationMeta.next_page || 1 : 1;

    graphql(
      CONVERSATION,
      {
        appKey: options.appKey,
        id: options.id,
        page: nextPage,
      },
      {
        success: (data) => {
          const conversation = data.app.conversation;

          const newConversation = Object.assign(
            {},
            {
              collection:
                nextPage > 1
                  ? getState().conversation.collection.concat(
                      conversation.messages.collection
                    )
                  : conversation.messages.collection,
              meta: conversation.messages.meta,
              loading: false,
            },
            conversation
          );
          dispatch(dispatchGetConversations(newConversation));

          if (cb) cb();
        },
        error: () => {},
      }
    );
  };
}

export function updateConversationTagList(options, cb) {
  return (dispatch, getState) => {
    graphql(
      UPDATE_CONVERSATION_TAG_LIST,
      {
        appKey: options.appKey,
        conversationId: options.id,
        tagList: options.tagList,
      },
      {
        success: (data) => {
          const tags = data.updateConversationTags.conversation.tagList;

          dispatch(
            getConversations(
              {
                page: getState()?.conversations?.meta?.current_page
                  ? getState()?.conversations?.meta?.current_page
                  : 1,
              },
              false
            )
          );

          dispatch(
            dispatchUpdateConversations({
              ...getState().conversation,
              tagList: tags,
            })
          );

          dispatch(
            dispatchUpdateListItemTagList({
              id: options.id,
              tagList: tags,
            })
          );

          if (cb) cb();
        },
        error: (error) => {
          error?.data?.errors.forEach((msg) => {
            console.log('msg ', msg);
            dispatch(errorMessage(msg?.message));
          });
        },
      }
    );
  };
}

export function clearConversation(cb) {
  return (dispatch, _getState) => {
    dispatch(dispatchGetConversations({}));
    if (cb) cb();
  };
}

export function typingNotifier(conversation: any, cb: any) {
  return (dispatch) => {
    graphql(
      TYPING_NOTIFIER,
      {
        appKey: conversation.appKey,
        id: conversation.key,
      },
      {
        success: () => {
          cb && cb();
        },
        error: (error) => {
          console.log(error);
        },
      }
    );
  };
}

export function insertComment(comment, cb, conversation) {
  return (dispatch) => {
    graphql(
      INSERT_COMMMENT,
      {
        appKey: conversation.appKey,
        id: conversation.key,
        message: comment,
      },
      {
        success: (data) => {
          // console.log("insert data ",data.insertComment.message);
          dispatch(
            appendMessage(data.insertComment.message, () => {
              cb && cb();
            })
          );
        },
        error: (error) => {
          error?.data?.errors.forEach((msg) => {
            dispatch(errorMessage(msg?.message));
          });

          cb && cb();
        },
      }
    );
  };
}

export function insertAppBlockComment(comment, cb) {
  return (_, getState) => {
    const blocks = {
      type: 'app_package',
      app_package: comment.provider.name,
      values: comment.values,
      schema: comment.provider.schema,
      wait_for_input: comment.provider.wait_for_input,
    };

    graphql(
      INSERT_APP_BLOCK_COMMMENT,
      {
        appKey: getState().app.key,
        id: getState().conversation.key,
        controls: blocks,
      },
      {
        success: (_) => {
          cb && cb();
        },
        error: (error) => {
          console.log(error);
          cb && cb();
        },
      }
    );
  };
}

export function insertNote(comment, cb, appKey?, conversationId?) {
  return (_) => {
    graphql(
      INSERT_NOTE,
      {
        appKey: appKey,
        id: conversationId,
        message: comment,
      },
      {
        success: (_) => {
          cb();
        },
        error: (error) => {
          console.log(error);
        },
      }
    );
  };
}

export function appendMessage(data, cb?: any, activeIdx?: number) {
  return (dispatch, getState) => {
    const newData = camelizeKeys(data);
    // update existing message
    const found = getState().openConversations.data[activeIdx].collection.find(
      (o) => o.key === newData.key
    );

    if (found) {
      const newCollection = getState().openConversations.data[
        activeIdx
      ].collection.map((o) => {
        if (o.key === newData.key) {
          return newData;
        } else {
          return o;
        }
      });

      const newMessages = Object.assign(
        {},
        getState().openConversations.data[activeIdx],
        {
          collection: newCollection,
        }
      );

      dispatch(getLastMessages(newMessages, activeIdx)); //updating in the openConvo
      dispatch(dispatchGetConversations(newMessages)); //updating in the conversations list
    } else {
      if (newData.appUser.kind !== 'agent') {
        playSound();
      }

      const newMessages = Object.assign(
        {},
        getState().openConversations.data[activeIdx],
        {
          // adding the newData msg to the front of collection
          collection: [newData].concat(
            getState().openConversations.data[activeIdx].collection
          ),
        },
        {
          assignee: newData.assignee
            ? {
                id: newData.assignee.id,
                email: newData.assignee.email,
                avatarUrl: newData.assignee.avatarUrl,
                isBot: newData.assignee.isBot,
              }
            : null,
          messages: Object.assign(
            {},
            getState().openConversations.data[activeIdx].messages,
            {
              // to add the newData msg to the head of the messages array
              collection: [newData].concat(
                getState().openConversations.data[activeIdx].messages.collection
              ),
            }
          ),
        },
        {
          seen:
            getState().openConversations.activeIndex == activeIdx
              ? true
              : false,
        }
      );

      dispatch(getLastMessages(newMessages, activeIdx)); //updating in the openConvo
      dispatch(dispatchGetConversations(newMessages)); //updating in the conversations list

      if (cb) cb();
    }
  };
}

export function assignUser(_key, _cb) {
  return (_dispatch, _getState) => {};
}

export function setLoading(val) {
  return (dispatch, getState) => {
    dispatch(
      dispatchUpdateConversations({
        ...getState().conversation,
        loading: val,
      })
    );
  };
}

export function updateTags(val) {
  return (dispatch, getState) => {
    dispatch(
      dispatchUpdateConversations({
        ...getState().conversation,
        tagList: val,
      })
    );
  };
}

export function toggleConversationPriority(_key, _cb) {
  return (_dispatch, _getState) => {};
}

export function updateConversationState(conversationId, state, cb) {
  return (dispatch, getState) => {
    graphql(
      UPDATE_CONVERSATION_STATE,
      {
        appKey: getState().app.key,
        conversationId: conversationId,
        state: state,
      },
      {
        success: (data) => {
          const conversation = data.updateConversationState.conversation;

          const newConversation = Object.assign(
            {},
            getState().conversation,
            conversation
          );
          dispatch(dispatchGetConversations(newConversation));

          if (cb) cb(newConversation);
        },
        error: (error) => {
          error?.data?.errors.forEach((msg) => {
            console.log('msg ', msg);
            dispatch(errorMessage(msg?.message));
          });
        },
      }
    );
  };
}

export function updateConversationPriority(cb) {
  return (dispatch, getState) => {
    graphql(
      TOGGLE_CONVERSATION_PRIORITY,
      {
        appKey: getState().app.key,
        conversationId: getState().conversation.id,
      },
      {
        success: (data) => {
          const conversation = data.toggleConversationPriority.conversation;
          const newConversation = Object.assign(
            {},
            getState().conversation,
            conversation
          );
          dispatch(dispatchGetConversations(newConversation));
          if (cb) cb(newConversation);
        },
        error: (error) => {
          error?.data?.errors.forEach((msg) => {
            console.log('msg ', msg);
            dispatch(errorMessage(msg?.message));
          });
        },
      }
    );
  };
}

export function assignAgent(agentId, conversation, cb = null) {
  return (dispatch, getState) => {
    graphql(
      ASSIGN_USER,
      {
        appKey: conversation.appKey,
        conversationId: conversation.id,
        appUserId: agentId,
      },
      {
        success: (data) => {
          const conversation = data.assignUser.conversation;
          const newConversation = Object.assign(
            {},
            getState().conversation,
            conversation
          );
          dispatch(dispatchGetConversations(newConversation));
          cb && cb();
        },
        error: () => {
          cb && cb();
        },
      }
    );
  };
}

function dispatchUpdateListItemTagList(data) {
  return {
    type: ActionTypes.UpdateConversationItem,
    data: data,
  };
}

export function dispatchGetConversations(data) {
  return {
    type: ActionTypes.GetConversation,
    data: data,
  };
}

function dispatchUpdateConversations(data) {
  return {
    type: ActionTypes.GetConversation,
    data: data,
  };
}

export function playSound() {
  pling.volume = 0.4;
  pling.play();
}

// Reducer
export default function reducer(
  state: any = {},
  action: {
    type: string;
    data: any;
  } = null
) {
  switch (action.type) {
    case ActionTypes.GetConversation: {
      return action.data;
    }
    case ActionTypes.UpdateConversation: {
      return { ...action.data, ...state };
    }
    default:
      return state;
  }
}
