import graphql from '../graphql/client';
import {
  READ_CONVO_LAST_MSG,
  UPDATE_CONVERSATION_STATE,
  UPDATE_CONVERSATION_TAG_LIST,
} from '../graphql/mutations';
import { CONVERSATION, CONVERSATIONS_UNREAD_COUNT } from '../graphql/queries';
import { getAppUser } from './app_user';
import {
  dispatchConversationsUnreadCount,
  getConversations,
  updateConversationReadFromOtherApp,
} from './conversations';
import { errorMessage } from './status_messages';

// ACTION TYPES
const Types = {
  // for all convs
  SET_LOADING: 'SET_LOADING',
  // for single conv
  SET_CONV_LOADING: 'SET_CONV_LOADING',
  OPEN_NEW_CONVERSATION: 'OPEN_NEW_CONVERSATION',
  UPDATE_CONVERSATION: 'UPDATE_CONVERSATION',
  CLOSE_CONVERSATION: 'CLOSE_CONVERSATION',
  UPDATE_ACTIVE_INDEX: 'UPDATE_ACTIVE_INDEX',
  UPDATE_START_INDEX: 'UPDATE_START_INDEX',
  GET_NEXT_TAB: 'GET_NEXT_TAB',
  CLEAR_OPEN_CONVERSATIONS: 'CLEAR_OPEN_CONVERSATIONS',
};

export function updateOpenConversationState(
  conversationId,
  state,
  cb,
  identifier,
  releaseOpenBtn
) {
  return (dispatch, getState) => {
    graphql(
      UPDATE_CONVERSATION_STATE,
      {
        appKey: getState().openConversations.data[identifier].appKey,
        conversationId: conversationId,
        state: state,
      },
      {
        success: (data) => {
          const conversation = data.updateConversationState.conversation;
          const newConversation = Object.assign(
            {},
            getState().openConversations.data[identifier],
            conversation
          );
          if (state == 'close') {
            dispatch(close_conversation(identifier));
          } else {
            dispatch(update_conversation(newConversation, identifier));
          }

          dispatch(
            getConversations(
              {
                page: getState()?.conversations?.meta?.current_page
                  ? getState()?.conversations?.meta?.current_page
                  : 1,
              },
              false
            )
          );

          releaseOpenBtn(false);
          if (cb) cb(newConversation);
        },
        error: (error) => {
          error?.data?.errors.forEach((msg) => {
            console.log('msg ', msg);
            dispatch(errorMessage(msg?.message));
            releaseOpenBtn(false);
          });
        },
      }
    );
  };
}

export function updateOpenConversationTagList(options, cb, releaseTagBtn) {
  return (dispatch, getState) => {
    graphql(
      UPDATE_CONVERSATION_TAG_LIST,
      {
        appKey: options.appKey,
        conversationId: options.id,
        tagList: options.tagList,
      },
      {
        success: (data) => {
          const tags = data.updateConversationTags.conversation.tagList;

          dispatch(
            getConversations(
              {
                page: getState()?.conversations?.meta?.current_page
                  ? getState()?.conversations?.meta?.current_page
                  : 1,
              },
              false
            )
          );

          let newConversation =
            getState().openConversations.data[options.identifier];
          newConversation.tagList = tags;

          dispatch(update_conversation(newConversation, options.identifier));
          releaseTagBtn(false);
          if (cb) cb();
        },
        error: (error) => {
          error?.data?.errors.forEach((msg) => {
            console.log('msg ', msg);
            dispatch(errorMessage(msg?.message));
            releaseTagBtn(false);
          });
        },
      }
    );
  };
}

// ACTIONS
const set_loading = () => {
  return {
    type: Types.SET_LOADING,
  };
};

const set_conv_loading = (idx, val) => {
  return {
    type: Types.SET_CONV_LOADING,
    data: {
      index: idx,
      value: val,
    },
  };
};

const open_new_conversation = (data: any, cb?: Function) => {
  cb && cb();
  return {
    type: Types.OPEN_NEW_CONVERSATION,
    data: { ...data, seen: true },
  };
};

const close_conversation = (idx: number) => {
  return {
    type: Types.CLOSE_CONVERSATION,
    data: idx,
  };
};

const update_conversation = (data: any, idx: number) => {
  return {
    type: Types.UPDATE_CONVERSATION,
    data: {
      data: data,
      idx: idx,
    },
  };
};

const update_active_index = (index: number) => {
  return {
    type: Types.UPDATE_ACTIVE_INDEX,
    data: index,
  };
};

// GRAPHQL FUNCTIONS
export const clear_open_conversations = () => {
  return {
    type: Types.CLEAR_OPEN_CONVERSATIONS,
  };
};

export const getOpenConversation = (opts, cb) => {
  return (dispatch, getState) => {
    dispatch(set_conv_loading(opts.index, true));
    const convMeta = getState().openConversations.data[opts.index].meta;
    const nextPage = convMeta.next_page || 1;

    graphql(
      CONVERSATION,
      {
        appKey: opts.appKey,
        id: opts.id,
        page: nextPage,
      },
      {
        success: (data) => {
          const conv = data.app.conversation;

          const newConversation = Object.assign(
            {},
            {
              collection:
                nextPage > 1
                  ? getState().openConversations.data[
                      opts.index
                    ].collection.concat(conv.messages.collection)
                  : conv.messages.collection,
              meta: conv.messages.meta,
              loading: false,
              seen: getState().openConversations.data[opts.index].seen,
            },
            conv
          );

          dispatch(update_conversation(newConversation, opts.index));
          if (cb) cb();
        },
        error: (err) => {
          set_conv_loading(opts.index, false);
          console.log('FUNC (getOpenConversation) error', err);
        },
      }
    );
  };
};
export const updateActiveIndex = (index: number) => {
  return (dispatch, getState) => {
    if (
      index >= -1 &&
      index < getState().openConversations.data.length &&
      index != getState().openConversations.activeIndex
    ) {
      dispatch(update_active_index(index));
    }
  };
};

export const readConversationLastMsg = (conversation: any) => {
  return (dispatch) => {
    graphql(
      READ_CONVO_LAST_MSG,
      {
        appKey: conversation.appKey,
        conversationId: conversation.id,
      },
      {
        success: (data) => {
          dispatch(
            updateConversationReadFromOtherApp(
              data.updateRead.conversation.lastMessage.readAt,
              conversation.key
            )
          );

          graphql(
            CONVERSATIONS_UNREAD_COUNT,
            { appKey: conversation.appKey },
            {
              success: (data) =>
                dispatch(
                  dispatchConversationsUnreadCount(data.app.unreadConversations)
                ),
              error: (err) => {
                console.log('FUNC (readConversationLastMsg) error inner ', err);
              },
            }
          );
        },
        error: (err) => {
          console.log('FUNC (readConversationLastMsg) error', err);
        },
      }
    );
  };
};

export function openConversation(conversation: any, cb?: Function) {
  return (dispatch, getState) => {
    dispatch(set_loading());
    dispatch(open_new_conversation({ key: conversation.key, seen: true }));
    const nextPage = 1;

    graphql(
      CONVERSATION,
      {
        appKey: conversation.appKey,
        id: conversation.key,
        page: nextPage,
      },
      {
        success: (data) => {
          const conversationData = data.app.conversation;

          const newConversation = Object.assign(
            {},
            {
              collection:
                nextPage > 1
                  ? conversation.collection.concat(
                      conversationData.messages.collection
                    )
                  : conversationData.messages.collection,
              meta: conversationData.messages.meta,
              loading: false,
              seen: true,
            },
            conversationData
          );
          const index = getState().openConversations.data.findIndex(
            (i) => i.key == conversation.key
          );
          if (index > -1) {
            dispatch(update_conversation(newConversation, index));
          }
          cb && cb();
          // dispatch(open_new_conversation(newConversation, cb));
          dispatch(readConversationLastMsg(conversation));
          conversation?.mainParticipant?.id &&
            dispatch(getAppUser(conversation?.mainParticipant?.id));
        },
        error: (err) => {
          cb && cb();
          console.log('FUNC (openConversation) error', err);
        },
      }
    );
  };
}

export function closeConversation(identifier: number, cb?: any) {
  return (dispatch, getState) => {
    dispatch(close_conversation(identifier));
    cb && cb();
  };
}

export const update_start_index = (value: number) => {
  return {
    type: Types.UPDATE_START_INDEX,
    data: value,
  };
};

export function getLastMessages(data, activeIdx) {
  return {
    type: Types.UPDATE_CONVERSATION,
    data: {
      data,
      idx: activeIdx,
    },
  };
}

export const get_next_tab = (value) => {
  return {
    type: Types.GET_NEXT_TAB,
    data: value,
  };
};

// INITIAL STATES

const initialStates = {
  loading: false,
  data: [],
  error: undefined,
  activeIndex: -1,
  startIndex: 0,
  perPage: 4,
};

// Reducer
export default function reducer(
  state: any = initialStates,
  action: {
    type: string;
    data: any;
  } = null
) {
  switch (action.type) {
    case Types.CLEAR_OPEN_CONVERSATIONS: {
      return initialStates;
    }
    case Types.SET_LOADING: {
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    }
    case Types.OPEN_NEW_CONVERSATION: {
      const newStartIdx =
        state.data.length >= state.perPage
          ? state.startIndex < state.data.length - state.perPage
            ? state.data.length - state.perPage + 1
            : state.startIndex + 1
          : state.startIndex;
      return {
        ...state,
        data: [...state.data, action.data],
        activeIndex: state.data.length,
        startIndex: newStartIdx,
        loading: false,
        error: undefined,
      };
    }
    case Types.CLOSE_CONVERSATION: {
      const newData = state.data;
      newData.splice(action.data, 1);
      const newStartIdx =
        state.startIndex >= length - state.perPage && state.startIndex > 0
          ? state.startIndex - 1
          : state.startIndex;
      return {
        ...state,
        data: newData,
        activeIndex: -1,
        startIndex: newStartIdx,
        loading: false,
        error: undefined,
      };
    }
    case Types.UPDATE_CONVERSATION: {
      const { data, idx } = action.data;
      let newData = [...state.data];
      newData[idx] = data;

      return {
        ...state,
        data: newData,
      };
    }
    case Types.SET_CONV_LOADING: {
      const { index, value } = action.data;
      let newData = [...state.data];
      newData[index] = { ...newData[index], loading: value };

      return {
        ...state,
        data: newData,
      };
    }
    case Types.UPDATE_ACTIVE_INDEX: {
      let newData = [...state.data];
      newData[action.data] = { ...state.data[action.data], seen: true };
      return {
        ...state,
        activeIndex: action.data,
        data: newData,
      };
    }
    case Types.GET_NEXT_TAB: {
      const newStartIndex =
        action.data == 0
          ? 0
          : action.data < state.startIndex + state.perPage
          ? state.startIndex
          : state.startIndex + 1;
      return {
        ...state,
        activeIndex: action.data,
        startIndex: newStartIndex,
      };
    }
    case Types.UPDATE_START_INDEX: {
      return {
        ...state,
        startIndex: action.data,
      };
    }
    default:
      return state;
  }
}
