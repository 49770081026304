import './styles.scss';

import React, { useEffect } from 'react';
import GraphComponent from './GraphView';
import TableComponent from './TableView';
import { BsGrid3X3 } from 'react-icons/bs';
import DateRangePicker from './DateRangePicker';
import Select from './Select';
import { SelectType } from './SelectOptions';
import { CSVLink } from 'react-csv';
import Box from '@mui/material/Box';
import { FaFileExport } from 'react-icons/fa';
import Spinner from './Spinner';

export default function ReportGraph({ states, setters, handleSubmit }) {
  const {
    loading,
    toggleView,
    resultData,
    selectedOption,
    dateValues,
    selectedData,
    options,
  } = states;
  const { setToggleView, setSelectedData, setDateValues, setSelectedOption } =
    setters;

  const handleChangeView = () => {
    if (!loading) {
      setToggleView(!toggleView);
    }
  };

  useEffect(() => {
    if (
      resultData != null &&
      selectedOption != null &&
      dateValues[0] != null &&
      dateValues[1] != null
    ) {
      if (selectedOption == SelectType.HOURLY) {
        setSelectedData(resultData.hourly);
      }
      if (selectedOption == SelectType.DAILY) {
        setSelectedData(resultData.daily);
      }
      if (selectedOption == SelectType.WEEKLY) {
        setSelectedData(resultData.weekly);
      }
      if (selectedOption == SelectType.MONTHLY) {
        setSelectedData(resultData.monthly);
      }
    }
  }, [selectedOption, dateValues, resultData]);

  return (
    <div className={`graph-container  ${loading ? 'loading' : ''}`}>
      <div className="graph-container-header">
        <div>
          <span>Total</span>
          {selectedData.length && selectedData[0].valueLabel ? (
            <h5>{resultData?.total}%</h5>
          ) : (
            <h5>{resultData?.total}</h5>
          )}
        </div>
        <div className="flex-container">
          {/* export file */}
          <div>
            {!loading && selectedData?.length ? (
              <Box sx={{ minWidth: 120 }}>
                <CSVLink
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '135px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  disabled={loading || selectedData?.length <= 0}
                  data={selectedData?.length > 0 ? selectedData : []}
                  filename={
                    selectedOption +
                    ' report ' +
                    new Date().toDateString() +
                    '.csv'
                  }
                  className="export-btn btn btn-secondary"
                  target="_blank"
                >
                  Export Data&nbsp;
                  <span style={{ color: '#fff' }}>
                    <FaFileExport />
                  </span>
                </CSVLink>
              </Box>
            ) : null}
          </div>
          <div className="view-change" onClick={handleChangeView}>
            <BsGrid3X3 />
          </div>
          <DateRangePicker
            value={dateValues}
            setValue={setDateValues}
            disabled={loading}
          />
          <Select
            options={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            disabled={loading}
          />
          <div>
            <button
              className="btn btn-primary"
              disabled={loading}
              style={{}}
              onClick={handleSubmit}
            >
              submit
            </button>
          </div>
        </div>
      </div>
      <div className="graph-container-body">
        {loading ? (
          <Spinner />
        ) : toggleView ? (
          <div>
            <TableComponent data={selectedData} />
          </div>
        ) : (
          <GraphComponent data={selectedData} />
        )}
      </div>
    </div>
  );
}
