import React, { useState } from 'react';
import graphql from '@chaskiq/store/src/graphql/client';
import { SelectType } from '../SelectOptions';
import { ConversationsCounts } from '@chaskiq/store/src/graphql/queries';
import ReportGraph from '../ReportGraph';

export default function UnattendedChat({ app }) {
  const [loading, setLoading] = useState(false);
  const [toggleView, setToggleView] = useState(false);
  const [dateValues, setDateValues] = useState<any>([null, null]);
  const [resultData, setResultData] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const getConversationsCounts = () => {
    const start = dateValues[0];
    const end = dateValues[1];
    if (start != null && end != null) {
      setLoading(true);
      graphql(
        ConversationsCounts,
        {
          appKey: app.key,
          type: 'Visitor',
          from: `${start.getFullYear()}-${
            start.getMonth() + 1
          }-${start.getDate()}`,
          to: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
        },
        {
          success: (data) => {
            const rData = data.app.typeConversationsCounts;
            setResultData(rData);
            let myOptions = [];
            if (rData?.hourly?.length) {
              myOptions.push(SelectType.HOURLY);
            }
            if (rData?.daily?.length) {
              myOptions.push(SelectType.DAILY);
            }
            if (rData?.weekly?.length) {
              myOptions.push(SelectType.WEEKLY);
            }
            if (rData?.monthly?.length) {
              myOptions.push(SelectType.MONTHLY);
            }
            setOptions(myOptions);
            setSelectedOption(myOptions[0]);
            setLoading(false);
          },
          error: (err) => {
            setLoading(false);
            console.log('error', err);
          },
        }
      );
    }
  };

  return (
    <>
      <ReportGraph
        states={{
          loading: loading,
          toggleView: toggleView,
          resultData: resultData,
          selectedOption: selectedOption,
          dateValues: dateValues,
          selectedData: selectedData,
          options: options,
        }}
        setters={{
          setToggleView: setToggleView,
          setSelectedData: setSelectedData,
          setDateValues: setDateValues,
          setSelectedOption: setSelectedOption,
        }}
        handleSubmit={getConversationsCounts}
      />
    </>
  );
}
