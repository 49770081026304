import { CSVLink } from 'react-csv';
import React from 'react';
import { useSelector } from 'react-redux';
import { parseJwt } from '@chaskiq/store/src/jwt';
import Tooltip from 'rc-tooltip';
import I18n from '../../../../../src/shared/FakeI18n';
import graphql from '@chaskiq/store/src/graphql/client';
import { ALL_PREDICATES_SEARCH } from '@chaskiq/store/src/graphql/mutations';
import { FaFileExport } from 'react-icons/fa';
import { MdOutlineDownloading } from 'react-icons/md';
import { BsDownload } from 'react-icons/bs';

export default function asyncCSV() {
  const [data, setData] = React.useState<Array<any>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const csvInstance = React.useRef<any | null>(null);
  const app = useSelector((state: any) => state.app);
  const segment = useSelector((state: any) => state.segment);

  const getData = (): void => {
    setLoading(true);
    const appKey = app.key;

    const jwtData = segment.jwt
      ? parseJwt(segment.jwt).data
      : segment.predicates;

    const predicates_data = {
      data: {
        predicates: jwtData.filter((o) => o.comparison),
      },
    };

    const options = {
      appKey: appKey,
      search: predicates_data,
      jwt: jwtData,
      page: -1,
      from: segment?.from,
      to: segment?.to,
      totalCount: true,
    };
    //name state phone created_at

    graphql(ALL_PREDICATES_SEARCH, options, {
      success: (data) => {
        const appUsers = data.predicatesSearch.appUsers.collection;
        setLoading(false);
        setData(appUsers);
      },
      error: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  return (
    <>
      <div>
        <Tooltip
          placement="bottom"
          overlay={I18n.t('segment_manager.export_segment')}
        >
          <div
            className="btn app_users_export flex justify-center items-center"
            onClick={getData}
          >
            {loading ? <MdOutlineDownloading /> : <FaFileExport />}
          </div>
        </Tooltip>
      </div>
      {data.length > 0 && (
        <div>
          <Tooltip
            placement="bottom"
            overlay={I18n.t('segment_manager.download_segment')}
          >
            <CSVLink
              id="the_export_of_app_users"
              className="btn app_users_export flex justify-center items-center"
              data={data}
              asyncOnClick={true}
              disabled={loading}
              onClick={(event, done) => {
                if (loading) return done(false);
                setData([]);
                done();
              }}
              ref={csvInstance}
            >
              <BsDownload color="green" />
            </CSVLink>
          </Tooltip>
        </div>
      )}
    </>
  );
}
