import React, { useState, useEffect, useRef, Fragment } from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import Tooltip from 'rc-tooltip';
import I18n from '../../shared/FakeI18n';
import { RiUserFill } from 'react-icons/ri';
import { last } from 'lodash';
import Moment from 'react-moment';
import { toCamelCase } from '@chaskiq/components/src/utils/caseConverter';
import ConversationEditor from './Editor';
import Progress from '@chaskiq/components/src/components/Progress';
import tw from 'twin.macro';
import QuickRepliesDialog from './QuickReplyDialog';
import ErrorBoundary from '@chaskiq/components/src/components/ErrorBoundary';
import { IoCheckmarkDone } from 'react-icons/io5';
import {
  CloseIcon,
  LabelIcon,
  BlockIcon,
} from '@chaskiq/components/src/components/icons';

import FilterMenu from '@chaskiq/components/src/components/FilterMenu';
import theme from '@chaskiq/components/src/components/textEditor/theme';
import themeDark from '@chaskiq/components/src/components/textEditor/darkTheme';
import EditorContainer from '@chaskiq/components/src/components/textEditor/editorStyles';
import DraftRenderer from '@chaskiq/components/src/components/textEditor/draftRenderer';
import styled from '@emotion/styled';
import TagDialog from '@chaskiq/components/src/components/TagDialog';
import { IoLogoWhatsapp } from 'react-icons/io';

import { successMessage } from '@chaskiq/store/src/actions/status_messages';

import {
  assignAgent,
  typingNotifier,
  insertComment,
  insertAppBlockComment,
  insertNote,
} from '@chaskiq/store/src/actions/conversation';

import {
  getOpenConversation,
  updateOpenConversationState,
  updateOpenConversationTagList,
} from '@chaskiq/store/src/actions/openConversations';

const EditorContainerMessageBubble = styled(EditorContainer)`
  //display: flex;
  //justify-content: center;

  // this is to fix the image on message bubbles
  .aspect-ratio-fill {
    display: none;
  }
  .aspectRatioPlaceholder.is-locked .graf-image {
    position: inherit;
  }
`;

const BgContainer = styled.div`
  /*background-image: radial-gradient(currentColor 2px, transparent 2px),
    radial-gradient(currentColor 2px, transparent 2px);
  background-size: calc(20 * 2px) calc(20 * 2px);
  background-position: 0 0, calc(10 * 2px) calc(10 * 2px);*/
  background-image: url('/assets/images/memphis-mini.png');
  /* background-size: calc(40px) calc(40px); */
  background-position: 0px 0px, calc(20px) calc(20px);
`;

type MessageItemType = {
  userOrAdmin: 'user' | 'admin';
  privateNote: boolean;
};

const MessageItem = styled.div<MessageItemType>`
  word-break: break-word;
  ${(props) =>
    props.userOrAdmin === 'user'
      ? tw`bg-white text-green-500`
      : props.privateNote
      ? tw`bg-yellow-300 text-black`
      : tw`bg-gray-200 text-green-500`}
`;

function Conversation({
  dispatch,
  conversation,
  app,
  events,
  openConversations,
  colseConversation,
  identifier,
  agents,
  current_user,
}) {
  const overflow = useRef<HTMLDivElement>(null);
  const messagesLength = conversation.collection
    ? conversation.collection.length
    : null;

  const { mainParticipant } = conversation;
  const participant = {
    ...mainParticipant,
    shortDisplayName: mainParticipant
      ? mainParticipant.displayName.slice(0, 1) +
        '-' +
        mainParticipant.displayName.slice(7)
      : '',
  };
  const [scrolling, setScrolling] = useState(false);
  const [openTagManager, setOpenTagManager] = useState(false);
  const [quickReplyDialogOpen, setQuickReplyDialogOpen] = useState(false);
  const [openBtn, releaseOpenBtn] = useState(false);
  const [tagBtn, releaseTagBtn] = useState(false);

  useEffect(() => {
    if (!scrolling) {
      scrollToLastItem();
    }
    setScrolling(false);
  }, [messagesLength]);

  const insertCommentDispatch = (comment, cb) => {
    dispatch(insertComment(comment, () => {}, conversation));
    cb && cb();
  };

  useEffect(() => {
    const inputFields = document.querySelectorAll(
      '.public-DraftEditor-content'
    );
    if (
      inputFields.length &&
      openConversations.activeIndex > -1 &&
      !openTagManager
    ) {
      inputFields[
        openConversations.activeIndex - openConversations.startIndex
        // @ts-ignore
      ]?.focus();
    }
  }, [openConversations.activeIndex, openConversations?.data[identifier]]);

  const insertNoteDispatch = (comment, cb) => {
    dispatch(
      insertNote(
        comment,
        () => {
          cb && cb();
        },
        app.key,
        conversation.id
      )
    );
  };

  const insertAppBlockCommentDispatch = (data, cb) => {
    dispatch(
      insertAppBlockComment(data, () => {
        cb && cb();
      })
    );
  };

  const handleScroll = (e) => {
    if (openConversations.data[identifier].loading) return;
    const element = e.target;
    if (element.scrollTop === 0) {
      // on top
      if (
        conversation.messages.meta.next_page &&
        !openConversations.data[identifier].loading
      ) {
        setScrolling(true);
        getMessages((item) => {
          scrollToItem(item);
        });
      }
    }
  };

  const scrollToItem = (item) => {
    if (item) {
      const q: HTMLDivElement = document.querySelector(`#message-id-${item}`);
      overflow.current.scrollTop = q.offsetHeight;
    } else {
      scrollToLastItem();
    }
  };

  const scrollToLastItem = () => {
    if (!overflow.current) return;
    overflow.current.scrollTop = overflow.current.scrollHeight;
  };

  const getMessages = (cb) => {
    const opts = {
      id: conversation.key,
      appKey: conversation.appKey,
      index: identifier,
    };

    const lastItem: any = last(conversation.messages.collection);

    dispatch(
      getOpenConversation(opts, () => {
        // this.getMainUser(this.state.conversation.mainParticipant.id)
        // TODO: this will scroll scroll to last when new items
        // are added on pagination (scroll up)!
        cb && cb(lastItem ? lastItem.id : null);
      })
    );
  };

  const typingNotifierDispatch = (cb) => {
    conversation.key ===
      openConversations.data[openConversations.activeIndex]?.key &&
      dispatch(
        typingNotifier(conversation, () => {
          cb && cb();
        })
      );
  };

  const setAgent = (agentId) => {
    dispatch(assignAgent(agentId, conversation));
  };

  const renderMessage = (o, userOrAdmin) => {
    const message = o;
    const messageContent = o.message;
    const key = `conversation-${conversation.key}-message-${o.key}`;

    const content = messageContent.serializedContent ? (
      <DraftRenderer
        key={key}
        raw={JSON.parse(messageContent.serializedContent)}
        html={messageContent.htmlContent}
      />
    ) : (
      <div
        key={key}
        dangerouslySetInnerHTML={{
          __html: messageContent.htmlContent,
        }}
      />
    );

    const flow = userOrAdmin === 'admin' ? 'flex-row-reverse' : '';

    return (
      <div
        id={`message-id-${message.key}`}
        className={`flex items-start py-2 message-font ${flow}`}
        key={`conversations-messages/${message.key}`}
      >
        <MessageItem
          userOrAdmin={userOrAdmin}
          privateNote={message.privateNote}
          className={`
            shadow 
            sm:rounded-lg
            flex-1 
            p-3 
            max-w-full
          `}
        >
          <div className="flex justify-between pb-4">
            <div className="flex items-center">
              <span className={`font-bold`}>{message.appUser.displayName}</span>
            </div>
            <span className={`flex items-center text-xs`}>
              <Moment fromNow ago>
                {message.createdAt}
              </Moment>

              {message?.authorableType !== 'AppUser' &&
                message?.appUser?.kind !== 'lead' && (
                  <span
                    className={`message-seen-marker ${
                      message.readAt ? 'seen' : ''
                    }`}
                  >
                    <IoCheckmarkDone />
                  </span>
                )}
            </span>
          </div>

          <EditorContainerMessageBubble>{content}</EditorContainerMessageBubble>
        </MessageItem>
      </div>
    );
  };

  const renderEventBlock = (o) => {
    const message = o;
    const messageContent = o.message;

    return (
      <div
        id={`message-id-${message.id}`}
        className={'flex items-start py-2 message-font'}
        key={`conversations-messages/${message.id}`}
      >
        <div
          className={`bg-yellow-300
          flex 
          overflow-hidden p-2 
          rounded-md mx-auto`}
        >
          <div className="flex flex-col justify-between">
            <span className={'flex text-xs text-center'}>
              <Moment fromNow ago>
                {message.createdAt}
              </Moment>

              {message?.authorableType !== 'AppUser' &&
                message?.appUser?.kind !== 'lead' && (
                  <span
                    className={`message-seen-marker ${
                      message.readAt ? 'seen' : ''
                    }`}
                  >
                    <IoCheckmarkDone />
                  </span>
                )}
            </span>

            <p className="text-md text-center font-bold">
              {messageContent.action}{' '}
              {messageContent.data.name || messageContent.data.email}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const updateTags = (tags) => {
    if (!tagBtn) {
      // to avoid double click which send to request
      releaseTagBtn(true);
      dispatch(
        updateOpenConversationTagList(
          {
            id: conversation.id,
            tagList: tags,
            appKey: conversation.appKey,
            identifier: identifier,
          },
          () => {
            dispatch(successMessage('tags updated'));
            setOpenTagManager(false);
          },
          releaseTagBtn
        )
      );
    }
  };

  return (
    <BgContainer
      className="flex-1 flex flex-col overflow-hidden-- h-screen"
      style={{ position: 'relative' }}
    >
      <div
        className="border-b flex px-6 py-3 items-center flex-none bg-white dark:bg-gray-800 dark:border-gray-700"
        style={{ height: '63px' }}
      >
        <div
          className="flex px-6 py-3 items-center flex-none bg-white"
          style={
            openConversations.data[identifier].seen ||
            !openConversations.data[identifier].id
              ? { ...Styles.SeenMarker }
              : { ...Styles.SeenMarker, border: '3px solid #0f0' }
          }
        >
          <div className="flex items-center">
            {conversation.mainParticipant &&
            conversation?.whatsappId != null ? (
              <div className="h-9 w-9 rounded-full mr-2 cursor-pointer flex justify-center items-center">
                <IoLogoWhatsapp
                  color="#33D952"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            ) : (
              <div className="h-9 w-9 rounded-full mr-2 cursor-pointer flex justify-center items-center bg-gray-600">
                <RiUserFill color="white" />
              </div>
            )}
            <h3
              className="mb-1 text-grey-darkest hidden md:flex 
            flex-col justify-center items-start"
            >
              {conversation.subject && (
                <span className="font-bold message-font">
                  {conversation.subject}
                </span>
              )}

              <span className="flex space-x-1 text-xs">
                <span className="font-extrabold hover:text-underline">
                  {conversation.mainParticipant && participant.shortDisplayName}
                </span>
              </span>
            </h3>
          </div>
          {openConversations.data[identifier].id && (
            <div className="ml-auto flex ">
              {/* close icon */}

              <Tooltip placement="left" overlay={'Close'}>
                <button
                  onClick={() => colseConversation(identifier)}
                  aria-label={'Close'}
                  className="focus:outline-none outline-none mr-1 rounded-full 
              bg-white hover:bg-gray-100 text-gray-800 font-semibold border 
              dark:hover:bg-gray-800 dark:text-gray-100 
              dark:bg-gray-900 dark:border-gray-200
              border-gray-400 shadow"
                >
                  <CloseIcon variant="rounded" />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {openConversations.data[identifier].id && (
        <div className="converstaion-actions">
          <Tooltip placement="left" overlay={'tag conversation'}>
            <button
              onClick={() => setOpenTagManager(true)}
              aria-label={'tag conversation'}
              className="focus:outline-none outline-none mr-1 rounded-full 
              bg-white hover:bg-gray-100 text-gray-800 font-semibold border 
              dark:hover:bg-gray-800 dark:text-gray-100 
              dark:bg-gray-900 dark:border-gray-200
              border-gray-400 shadow still-fouced"
            >
              <LabelIcon variant="rounded" />
            </button>
          </Tooltip>

          {openTagManager && (
            <TagDialog
              title={'manage conversation tags'}
              tags={conversation.tagList}
              saveHandler={(tags) => updateTags(tags)}
              closeHandler={() => setOpenTagManager(false)}
            ></TagDialog>
          )}

          {current_user.isAdmin && (
            <FilterMenu
              options={agents.map((o) => ({
                key: o.email,
                name: o.name || o.email,
                id: o.id,
              }))}
              value={conversation.assignee ? conversation.assignee.email : ''}
              filterHandler={(data) => setAgent(data.id)}
              position={'right'}
              origin={'top-50'}
              triggerButton={(cb) => {
                return (
                  <Tooltip
                    placement="left"
                    overlay={I18n.t('conversation.actions.assign_agent')}
                  >
                    <div
                      onClick={cb}
                      className="flex flex-shrink-0 h-10 w-10 mr-1 rounded-full
                    bg-white hover:bg-gray-100 text-gray-800 border-gray-400 font-semibold
                    dark:hover:bg-gray-800 dark:text-gray-100 
                    dark:bg-gray-900 dark:border-gray-200
                    border shadow items-center justify-center"
                    >
                      {conversation.assignee && (
                        <img
                          className="h-6 w-6 rounded-full"
                          src={conversation.assignee.avatarUrl}
                          alt={conversation.assignee.name}
                        />
                      )}
                    </div>
                  </Tooltip>
                );
              }}
            />
          )}

          <Tooltip
            placement="left"
            overlay={I18n.t(
              `conversation.actions.${
                conversation.state === 'closed' ? 'reopen' : 'ban'
              }`
            )}
          >
            <button
              onClick={() => {
                if (!openBtn) {
                  // to avoid double click which send to request
                  const option =
                    conversation.state === 'closed' ? 'reopen' : 'close';
                  releaseOpenBtn(true);
                  dispatch(
                    updateOpenConversationState(
                      conversation.id,
                      option,
                      null,
                      identifier,
                      releaseOpenBtn
                    )
                  );
                }
              }}
              aria-label={I18n.t(
                `conversation.actions.${
                  conversation.state === 'closed' ? 'reopen' : 'close'
                }`
              )}
              className={`
              focus:outline-none outline-none mr-1 rounded-full
              font-semibold border
              border-gray-400 shadow
              ${
                conversation.state === 'closed'
                  ? 'bg-green-600 border-green-700 hover:bg-green-700 hover:border-green-800 text-gray-100'
                  : 'bg-white hover:bg-gray-100 text-gray-800 dark:hover:bg-gray-800 dark:text-gray-100 dark:bg-gray-900 dark:border-gray-200'
              }
              `}
            >
              <BlockIcon variant="rounded" />
            </button>
          </Tooltip>
        </div>
      )}
      {openConversations.data[identifier].id ? (
        <div
          ref={overflow}
          className="overflow-y-scroll"
          onScroll={handleScroll}
          style={{
            height: 'calc(100vh - 220px)',
          }}
        >
          <div className="flex flex-col-reverse px-6 py-4">
            <ErrorBoundary>
              {conversation &&
                conversation.messages &&
                conversation.collection.map((message, idx) => {
                  const isReplied = message.message.state === 'replied';
                  const userOrAdmin =
                    !isReplied &&
                    message.appUser &&
                    message.appUser.kind === 'agent'
                      ? 'admin'
                      : 'user';

                  return (
                    <MessageItemWrapper
                      key={`message-item-${conversation.key}-${message.key}-${idx}`}
                      data={message}
                      events={events}
                      conversation={conversation}
                    >
                      <ThemeProvider
                        theme={
                          userOrAdmin === 'admin'
                            ? message.privateNote
                              ? theme
                              : themeDark
                            : theme
                        }
                      >
                        {message.message.blocks ? (
                          <RenderBlocks
                            conversation={conversation}
                            message={message}
                            app={app}
                            dispatch={dispatch}
                          />
                        ) : message.message.action ? (
                          renderEventBlock(message)
                        ) : (
                          renderMessage(message, userOrAdmin)
                        )}
                      </ThemeProvider>
                    </MessageItemWrapper>
                  );
                })}

              {openConversations.data[identifier]?.loading &&
                conversation?.key ===
                  openConversations.data[openConversations.activeIndex]
                    ?.key && (
                  <div className="m-2">
                    <Progress size={4} />
                  </div>
                )}
            </ErrorBoundary>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              border: '6px solid #f3f3f3',
              borderRadius: '50%',
              borderTop: '6px solid #3498db',
              width: '60px',
              height: '60px',
              WebkitAnimation: 'spin .5s linear infinite',
              animation: 'spin .5s linear infinite',
            }}
          ></div>
        </div>
      )}

      {openConversations.data[identifier].id && (
        <div className="pb-3 px-4 flex-none mt-auto">
          <div className="bg-white flex rounded-lg border border-grey overflow-hidden shadow-lg">
            <ConversationEditor
              conversation={conversation}
              insertAppBlockComment={insertAppBlockCommentDispatch}
              insertComment={insertCommentDispatch}
              typingNotifier={typingNotifierDispatch}
              insertNote={insertNoteDispatch}
            />
          </div>
        </div>
      )}
      {openConversations.data[identifier].id && (
        <QuickRepliesDialog
          closeHandler={() => setQuickReplyDialogOpen(null)}
          open={quickReplyDialogOpen}
        ></QuickRepliesDialog>
      )}
    </BgContainer>
  );
}

function MessageItemWrapper({ conversation, data, events, children }) {
  useEffect(() => {
    // mark as read on first render
    setTimeout(sendRead, 300);
  }, []);

  function sendRead() {
    if (!data.readAt) {
      events &&
        events.perform(
          'receive_conversation_part',
          Object.assign(
            {},
            {
              conversation_key: conversation.key,
              message_key: data.key,
            },
            { email: data.email }
          )
        );
    }
  }

  return <Fragment>{children}</Fragment>;
}

function RenderBlocks({ message, app, conversation, dispatch }) {
  const { data, blocks } = toCamelCase(message).message;

  const renderBlockRepresentation = () => {
    // TODO: display labels, schema buttons
    let output = null;
    switch (blocks.type) {
      case 'app_package':
        output = (
          <div>
            <div
              className="text-gray-800 text-xs
            font-bold uppercase tracking-wide"
            >
              <div
                className="inline-flex items-baseline px-2.5 py-0.5 rounded-full
            text-xs font-light bg-green-100 text-green-800 md:mt-2 lg:mt-0"
              >
                <span>{blocks.appPackage}</span>
              </div>
            </div>

            <br />

            {/* <DefinitionRenderer
              schema={schema}
              values={blocks.values}
              updatePackage={updatePackage}
              disabled={true}
            /> */}
          </div>
        );
        break;
      case 'ask_option':
        output = <p>ask option</p>;
        break;
      case 'data_retrieval':
        output = <p>data retrieval</p>;
        break;
      default:
        null;
    }

    return (
      <div
        style={{
          opacity: 0.96,
        }}
        className={`
        w-full
        bg-white
        dark:bg-black
        dark:text-white
        dark:border-gray-900
        opacity-75
        border
        border-gray-400
        text-gray-600
        shadow-lg
        flex 
        overflow-hidden p-2 
        rounded-md mx-auto
        `}
      >
        <div className="w-full flex flex-col justify-between">{output}</div>
      </div>
    );
  };

  if (blocks.type === 'app_package') {
    return (
      <div
        id={`message-id-${message.id}`}
        className={'flex items-start py-2 message-font'}
        key={`conversations-messages/${message.id}`}
      >
        {renderBlockRepresentation()}
      </div>
    );
  }

  const item = message.message.data;
  if (!item) {
    return (
      <p className="message-font leading-5 font-medium text-gray-500 py-2 flex justify-center">
        <a
          href="#"
          className="relative inline-flex items-center rounded-full border border-gray-400 px-3 py-0.5 message-font bg-white"
        >
          <span className="absolute flex-shrink-0 flex items-center justify-center">
            <span
              className="h-1.5 w-1.5 rounded-full bg-yellow-200 border border-black"
              aria-hidden="true"
            ></span>
          </span>
          <span className="ml-3.5 font-medium text-gray-700">
            waiting for reply
          </span>
        </a>
      </p>
    );
  }

  let blockElement;

  switch (item.element) {
    case 'button':
      blockElement = (
        <p>
          <strong>reply button:</strong> {item.label}
        </p>
      );

      break;
    default:
      if (blocks.type === 'app_package') {
        blockElement = (
          <div>
            <p>{blocks.appPackage}</p>

            <br />

            <div>
              {data && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.formattedText,
                  }}
                />
              )}
            </div>
          </div>
        );

        break;
      }

      if (blocks.type === 'data_retrieval') {
        const dataList = Object.keys(message.message.data).map((k) => {
          return (
            <p key={`data-message-${message.id}-${k}`}>
              {k}: {message.message.data[k]}
            </p>
          );
        });

        blockElement = (
          <Fragment>
            <strong>replied:</strong>
            {dataList}
          </Fragment>
        );
        break;
      } else {
        blockElement = <p>{JSON.stringify(message.message.data)}</p>;
        break;
      }
  }

  return (
    <div
      id={`message-id-${message.id}`}
      className={'flex items-start py-2 message-font'}
      key={`conversations-messages/${message.id}`}
    >
      <div
        className={`bg-green-400
        flex 
        overflow-hidden p-2 
        rounded-md mx-auto text-white`}
      >
        <div className="flex flex-col justify-between">
          <span className={'flex text-xs text-center'}>
            <Moment fromNow ago>
              {message.createdAt}
            </Moment>

            {message?.authorableType !== 'AppUser' &&
              message?.appUser?.kind !== 'lead' && (
                <span
                  className={`message-seen-marker ${
                    message.readAt ? 'seen' : ''
                  }`}
                >
                  <IoCheckmarkDone />
                </span>
              )}
          </span>

          <div className="text-md text-center text-bold">{blockElement}</div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { auth, app, app_user, current_user, drawer, openConversations } =
    state;
  const { isAuthenticated } = auth;
  const { jwt } = auth;

  return {
    jwt,
    current_user,
    app_user,
    app,
    drawer,
    isAuthenticated,
    openConversations,
  };
}

export default withRouter(connect(mapStateToProps)(Conversation));

const Styles = {
  SeenMarker: {
    borderRadius: '1rem',
    padding: '2px',
    width: '100%',
  },
};
