import { ActionType } from '../constants/action_types';
import {
  GET_ALL_REPORT_MENU_ITEMS,
  GET_REPORT_GRAPH_DATA,
} from '../graphql/queries';
import {
  CREATE_REPORT_MENU_ITEM,
  GET_REPORT_MENU_ITEM,
  UPDATE_REPORT_MENU_ITEM_BY_ID,
  DELETE_REPORT_MENU_ITEM_BY_ID,
} from '../graphql/mutations';
import graphql from '../graphql/client';

// TYPES
const Types = {
  LOADING: 'LOADING',
  SET_DATA: 'SET_DATA',
  ADD_NEW_ITEM: 'ADD_NEW_ITEM',
  SET_ITEM: 'SET_ITEM',
  UPDATE_ITEM_BY_ID: 'UPDATE_ITEM_BY_ID',
  DELETE_ITEM_BY_ID: 'DELETE_ITEM_BY_ID',
};

// ACTIONS

const set_loading = (status) => {
  return {
    type: Types.LOADING,
    data: status,
  };
};

const set_data = (data) => {
  return {
    type: Types.SET_DATA,
    data: data,
  };
};

const add_new_item = (item) => {
  return {
    type: Types.ADD_NEW_ITEM,
    data: item,
  };
};

const set_item = (item) => {
  return {
    type: Types.SET_ITEM,
    data: item,
  };
};

const update_item_by_id = (id, updatedItem) => {
  return {
    type: Types.UPDATE_ITEM_BY_ID,
    data: {
      id: id,
      item: updatedItem,
    },
  };
};

const delete_item_by_id = (id) => {
  return {
    type: Types.DELETE_ITEM_BY_ID,
    data: id,
  };
};
// FUNCTIONS
export const getAllReportMenuItems = () => {
  return (dispatch, getState) => {
    dispatch(set_loading(true));
    graphql(
      GET_ALL_REPORT_MENU_ITEMS,
      {
        appKey: getState().app.key,
      },
      {
        success: (data) => {
          dispatch(set_data(data.app.setups));
          dispatch(set_loading(false));
        },
        error: (e) => {
          console.log('ERROR', e);
        },
      }
    );
  };
};

export const createReportMenuItem = (menuItem, cb) => {
  return (dispatch, getState) => {
    graphql(
      CREATE_REPORT_MENU_ITEM,
      {
        appKey: getState().app.key,
        firstParameter: menuItem.firstParameter,
        secondParameter: menuItem.secondParameter,
        properties: {
          title: menuItem.title,
          tag: menuItem.tag,
        },
      },
      {
        success: (data) => {
          const item = data.createSetup.setup;
          dispatch(add_new_item(item));
          cb && cb(item);
        },
        error: (e) => {
          console.log('ERROR', e);
        },
      }
    );
  };
};

export const getReportMenuItemById = (itemId) => {
  return (dispatch, getState) => {
    graphql(
      GET_REPORT_MENU_ITEM,
      {
        appKey: getState().app.key,
        id: itemId,
      },
      {
        success: (data) => {
          dispatch(set_item(data.app.setup));
        },
        error: (e) => {
          console.log('ERROR', e);
        },
      }
    );
  };
};

export const updateReportMenuItemById = (updatedItem, cb) => {
  return (dispatch, getState) => {
    const id = getState().reportMenuItems.item.id;
    graphql(
      UPDATE_REPORT_MENU_ITEM_BY_ID,
      {
        firstParameter: updatedItem.firstParameter,
        secondParameter: updatedItem.secondParameter,
        id: id,
        properties: {
          title: updatedItem.title,
          tag: updatedItem.tag,
        },
      },
      {
        success: (data) => {
          dispatch(update_item_by_id(id, data.updateSetup.setup));
          cb && cb();
        },
        error: (e) => {
          console.log('ERROR', e);
        },
      }
    );
  };
};

export const deleteReportMenuItem = (cb) => {
  return (dispatch, getState) => {
    const id = getState().reportMenuItems.item.id;
    graphql(
      DELETE_REPORT_MENU_ITEM_BY_ID,
      {
        id: id,
      },
      {
        success: (data) => {
          dispatch(delete_item_by_id(data.deleteSetup.setup.id));
          cb && cb();
        },
        error: (e) => {
          console.log('ERROR', e);
        },
      }
    );
  };
};

export const getGraphData = (opts, cb) => {
  const { id, from, to, tag } = opts;
  return (dispatch, getState) => {
    const appKey = getState().app.key;
    graphql(
      GET_REPORT_GRAPH_DATA,
      {
        appKey: appKey,
        id: id,
        from: from,
        to: to,
      },
      {
        success: (data) => {
          cb && cb(data.app.setupsCounts);
        },
        error: (e) => {
          console.log('ERROR', e);
          cb && cb(null);
        },
      }
    );
  };
};

// Reducer
export default function reducer(
  state = {
    loading: false,
    data: [],
    item: null,
  },
  action: ActionType = {}
) {
  switch (action.type) {
    case Types.LOADING: {
      return {
        ...state,
        loading: action.data,
      };
    }
    case Types.SET_DATA: {
      return {
        ...state,
        data: action.data,
      };
    }
    case Types.ADD_NEW_ITEM: {
      return {
        ...state,
        data: [...state.data, action.data],
      };
    }
    case Types.SET_ITEM: {
      return {
        ...state,
        item: action.data,
      };
    }
    case Types.UPDATE_ITEM_BY_ID: {
      const { id, item } = action.data;
      const newData = state.data;
      let index = -1;
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].id == id) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        newData[index] = item;
      }
      return {
        ...state,
        data: newData,
        item: item,
      };
    }
    case Types.DELETE_ITEM_BY_ID: {
      const newData = state.data.filter((i) => i.id !== action.data);
      return {
        ...state,
        item: null,
        data: newData,
      };
    }
    default:
      return state;
  }
}
