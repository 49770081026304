import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect({
  options = [],
  selectedOption,
  setSelectedOption,
  disabled,
}) {
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // return <div>test</div>
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOption != null ? selectedOption : ''}
          // value=""
          label={''}
          onChange={handleChange}
          className="select"
          disabled={disabled ? true : false}
        >
          {options.map((o, idx) => (
            <MenuItem key={idx} value={o}>
              {o}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
