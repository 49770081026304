import React from 'react';
import Content from '@chaskiq/components/src/components/Content';
import Overview from './reports/Overview';
import { connect } from 'react-redux';
import moment from 'moment';
import { Switch, Route, withRouter } from 'react-router-dom';
import Package from './reports/Package';
import ChatVolume from './reports/ChatVolume';
import MissedChat from './reports/MissedChat';
import OfflineConversations from './OfflineConversations';
import {
  setCurrentSection,
  setCurrentPage,
} from '@chaskiq/store/src/actions/navigation';
import TotalTraffic from './reports/TotalTraffic';
import UnattendedChat from './reports/UnattendedChat';
import TagChat from './reports/TagChat';
import DynamicMenuItem from './reports/DynamicMenuItem';

function Reports({ match, dispatch, app }) {
  React.useEffect(() => {
    dispatch(setCurrentPage('Reports'));
    dispatch(setCurrentSection('Reports'));
  }, []);

  const initialData = {
    loading: true,
    from: moment().add(-1, 'week'),
    to: moment(), // .add(-1, 'day')
  };

  const [dashboard, _setDashboard] = React.useState(initialData);

  return (
    <div>
      <Content>
        <Switch>
          <Route
            path={`${match.url}/leads`}
            render={(props) => <p>somewhere</p>}
          />

          <Route
            path={`${match.url}/packages/:pkg`}
            render={(props) => {
              return (
                <Package
                  app={app}
                  dashboard={dashboard}
                  pkg={props.match.params.pkg}
                />
              );
            }}
          />

          <Route
            path={`${match.url}/overview`}
            render={(props) => <Overview app={app} dashboard={dashboard} />}
          />

          <Route
            path={`${match.url}/chat volume`}
            render={(props) => <ChatVolume app={app} />}
          />

          <Route
            path={`${match.url}/unattended chats`}
            render={(props) => <UnattendedChat app={app} />}
          />

          <Route
            path={`${match.url}/total traffic`}
            render={(props) => <TotalTraffic app={app} />}
          />

          <Route
            path={`${match.url}/missed chats`}
            render={(props) => <MissedChat app={app} />}
          />

          <Route
            path={`${match.url}/tag chats`}
            render={(props) => <TagChat app={app} />}
          />

          <Route
            path={`${match.url}/custom-reports/:id/:title`}
            render={(props) => <DynamicMenuItem app={app} />}
          />

          <Route
            path={`${match.url}/conversations`}
            render={(props) => <OfflineConversations app={app} />}
          />
        </Switch>
      </Content>
    </div>
  );
}

function mapStateToProps(state) {
  const { app } = state;
  return {
    app,
  };
}

export default withRouter(connect(mapStateToProps)(Reports));
